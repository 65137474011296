angular.module('PSGCExpertApp').controller('RoomCtrl', [
	'$scope',
	'$routeParams',
	'$rootScope',
	'$timeout',
	'desktopNotification',
	'Helpers',
	function(
		$scope,
		$routeParams,
		$rootScope,
		$timeout,
		desktopNotification,
		Helpers
	) {
		$scope.snapp = {}
		$scope.snappUser = {}
		$scope.snappExpert = {}
		$scope.snappComments = []
		$scope.pageTitle = 'Room'
		$scope.spinnerArticle = true
		$scope.users = []
		$scope.currentUser = Parse.User.current()
		$scope.messageText = ''
		$scope.sendEnabled = false
		$scope.showAlertMessage = false
		$scope.alertMessageText = ''

		if (
			desktopNotification.isSupported() &&
			desktopNotification.currentPermission() === 'default'
		) {
			desktopNotification.requestPermission().then(
				permission => {
					// User allowed the notification
					desktopNotification.show(
						'Thanks for enabling desktop notifications',
						{
							body: '',
							onClick: function() {
								// Handle click event
							},
						}
					)
				},
				permission => {
					// User denied the notification
				}
			)
		}

		let snappCommentQuery = new Parse.Query(
			Parse.Object.extend('SnappComments')
		)
		let Snapp = Parse.Object.extend('Snapp')
		let snapp = new Snapp()
		snapp.id = $routeParams.id
		snappCommentQuery.equalTo('snapp', snapp)
		let count = snappCommentQuery
			.count()
			.then(function(count) {
				$scope.totalCount = count
			})
			.catch(function(err) {
				console.error(err)
			})

		$scope.listQuery = function() {
			$scope.spinnerArticle = true
			$scope.nextPageDisabled = false

			let snappQuery = new Parse.Query(Parse.Object.extend('Snapp'))
			snappQuery
				.include('expert')
				.include('snappUser')
				.include('expertIdentifiedPlant')
				.get($routeParams.id)
				.then(function(snapp) {
					$scope.expertIdentifiedPlant = snapp.get('expertIdentifiedPlant')
					let heading = snapp.get('isSnappIdentified')
						? 'Plant identified as ' +
						  $scope.expertIdentifiedPlant.get('latinName')
						: 'Unidentified'
					// get the snapp
					$scope.snapp = {
						title: snapp.get('title'),
						heading,
						objectId: snapp.id,
						object: snapp,
						isSnappIdentified: snapp.get('isSnappIdentified'),
						createdAt: snapp.get('createdAt'),
						snappType: snapp.get('snappType'),
						expertIdentifiedPlant: {
							name: $scope.expertIdentifiedPlant.get('latinName'),
							date: moment(snapp.get('expertIdentifiedDate')).fromNow(),
							timeToIdentify: moment(snapp.get('expertIdentifiedDate')).from(
								snapp.get('sendForIdentification'),
								true
							),
						},
						image: snapp.get('thumbImage').url(),
					}
					$scope.snappUser = snapp.get('snappUser')

					$scope.snappExpert = snapp.get('expert')

					// search for all the snapp comments
					let snappCommentsQuery = new Parse.Query(
						Parse.Object.extend('SnappComments')
					)
					snappCommentsQuery
						.ascending('createdAt')
						.include('user')
						.include('expert')
						.equalTo('snapp', snapp)
					let snappCommentsSubQuery = new Parse.Query(
						Parse.Object.extend('SnappComments')
					)
					snappCommentsSubQuery
						.ascending('createdAt')
						.include('user')
						.include('expert')
						.equalTo('snapp', snapp)
						.notEqualTo('user', $scope.snappExpert)
						.notEqualTo('expert', $scope.snappExpert)
					let snappCommentsSub = snappCommentsSubQuery.subscribe()
					snappCommentsSub.on('create', snappComment => {
						addSnappComment(snappComment)
						let userSending = snappComment.get('user')
						desktopNotification.show(snappComment.comments)
						$scope.$apply()
					})
					return snappCommentsQuery.find()
				})
				.then(function(snappComments) {
					if (snappComments.length > 0) {
						$scope.snappComments = []
						for (const snappComment of snappComments) {
							$scope.lastIndexValue++
							addSnappComment(snappComment)
						}
						// enable the button
						$scope.sendEnabled = false
						if ($rootScope.totalCount === $scope.lastIndexValue) {
							$scope.nextPageDisabled = true
						}
					} else {
						$scope.nextPageDisabled = true
						$scope.emptyMessageDisplay = true
					}

					$scope.spinnerArticle = false
					$scope.$apply()
					updateScroll()

					// update the snapp to set the un
					$scope.snapp.object.set('unreadCommentsCount', 0)
					return $scope.snapp.object.save()
				})
				.catch(function(err) {
					console.error(err)
				})
		}

		$scope.addMessage = function() {
			const SnappComment = Parse.Object.extend('SnappComments')
			let snappComment = new SnappComment()

			$scope.sendEnabled = false
			snappComment.set({
				comments: $scope.messageText,
				expert: $scope.currentUser.toPointer(),
				snapp: $scope.snapp.object.toPointer(),
			})
			snappComment
				.save()
				.then(function(snappComment) {
					$scope.sendEnabled = true
					$scope.messageText = ''
					addSnappComment(snappComment)
					updateScroll()
				})
				.catch(function(snapp, err) {
					$scope.sendEnabled = true
					$scope.alertMessageText = 'Could not send message'
					$scope.showAlertMessage = true
					$timeout(function() {
						$scope.showAlertMessage = false
					}, 3000)
				})
		}
		$scope.listQuery()

		function addSnappComment(snappComment) {
			let snappCommentUser =
				snappComment.get('user') || snappComment.get('expert')

			$scope.snappComments.push({
				username: Helpers.getUsername(snappCommentUser),
				comments: snappComment.get('comments'),
				objectId: snappComment.id,
				object: snappComment,
				me: snappCommentUser.id === $scope.currentUser.id,
				createdAt: moment(snappComment.get('createdAt')).fromNow(),
			})
		}

		function updateScroll() {
			var chatMessages = document.getElementById('chat')
			chatMessages.scrollTop = chatMessages.scrollHeight
		}
	},
])
