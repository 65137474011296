angular.module('PSGCExpertApp').controller('ArticleListCtrl', [
	'$scope',
	'$rootScope',
	'CountQuery',
	function($scope, $rootScope, CountQuery) {
		$scope.pageTitle = 'Article List'
		$scope.spinnerArticle = true

		$scope.articleCount = CountQuery.getCount(
			'ExternalArticle',
			'user',
			Parse.User.current(),
			'equalTo'
		)
		$scope.listQuery = function() {
			$scope.spinnerArticle = true
			$scope.nextPageDisabled = false
			var articleTable = Parse.Object.extend('ExternalArticle')
			var getArticleQuery = new Parse.Query(articleTable)
			getArticleQuery
				.descending('createdAt')
				.equalTo('user', Parse.User.current())
				.limit($rootScope.itemsPerPage)
				.skip($rootScope.currentPage * $rootScope.itemsPerPage)
				.find()
				.then(function(articleObject) {
					if (articleObject.length > 0) {
						$scope.jsonArrayList = []
						$scope.lastIndexValue =
							$rootScope.currentPage * $rootScope.itemsPerPage
						for (var i = 0; i < articleObject.length; i++) {
							$scope.lastIndexValue++
							$scope.jsonArrayList.push({
								title: articleObject[i].get('title'),
								objectId: articleObject[i].id,
								object: articleObject[i],
								index: $scope.lastIndexValue,
								status: articleObject[i].get('status'),
							})
						}
						if ($rootScope.totalCount === $scope.lastIndexValue) {
							$scope.nextPageDisabled = true
						}
					} else {
						$scope.nextPageDisabled = true
						$scope.emptyMessageDisplay = true
					}

					$scope.spinnerArticle = false
				})
		}

		$scope.$watch('totalCount', function() {
			if ($rootScope.totalCount !== null) {
				$scope.listQuery()
			}
		})

		$scope.changeStatus = function(objectId, mode) {
			var sureDelete = confirm('Are you sure want to send for Approval')
			if (sureDelete === true) {
				$scope.spinnerArticle = true

				var externalTable = Parse.Object.extend('ExternalArticle')
				var externalObject = new externalTable()
				externalObject.id = objectId
				externalObject.set('status', 'Awaiting')
				externalObject
					.save(null)
					.then(result => {
						alert('send successfully')
						$scope.listQuery()
					})
					.catch(result => {
						response.error(error)
					})
			}
			$scope.spinnerArticle = false
		}

		// If user changes the list length (Drop Down).
		$scope.listLength = function(itemsPerPage) {
			$rootScope.itemsPerPage = itemsPerPage

			if ($scope.lastIndexValue > itemsPerPage) {
				$rootScope.currentPage = Math.floor(
					$scope.lastIndexValue / itemsPerPage
				)
			} else {
				$rootScope.currentPage = 0
			}
			$scope.listQuery()
		}

		$scope.prevPageDisabled = function() {
			return $scope.currentPage === 0 ? 'disabled' : ''
		}

		$scope.prevPage = function() {
			if ($rootScope.currentPage > 0) {
				$rootScope.currentPage--
				$scope.listQuery()
			}
		}

		$scope.nextPage = function() {
			if ($scope.nextPageDisabled !== true) {
				$rootScope.currentPage++
				$scope.listQuery()
			}
		}
	},
])
