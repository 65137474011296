
angular.module('PSGCExpertApp').controller('ProfileViewCtrl', [
	'$scope',
	'$rootScope',
	function($scope, $rootScope) {
		$scope.spinnerprofile = true

		$scope.profileImage = Parse.User.current().get('profileImage')

		var usesrProfile = Parse.Object.extend('ExpertDetails')
		// Get user profile query to display in the text box.
		var getProfileQuery = new Parse.Query(usesrProfile)
		getProfileQuery
			.equalTo('user', Parse.User.current())
			.limit(1)
			.include('region')
			.find()
			.then(function(UserprofileObject) {
				$scope.profileObject = UserprofileObject.id

				if (UserprofileObject.length > 0) {
					var regionObject = UserprofileObject[0].get('region')
					if (regionObject) {
						var regionName = regionObject.get('regionName')
					}

					var objectId = UserprofileObject[0]
					$scope.job = UserprofileObject[0].get('job')
					$scope.phoneNumber = UserprofileObject[0].get('phoneNumber')
					$scope.accountName = UserprofileObject[0].get('accountName')
					$scope.accountNumber = UserprofileObject[0].get('accountNumber')
					$scope.paypalAccount = UserprofileObject[0].get('paypalAccount')

					var expertRegions = UserprofileObject[0].get('expertRegions')
					if (expertRegions) {
						$scope.jsonRegionArrayList = []
						for (var i = 0; i < expertRegions.length; i++) {
							$scope.expertRegions = true
							var regionTable = Parse.Object.extend('Region')
							var getRegionQuery = new Parse.Query(regionTable)
							getRegionQuery
								.equalTo('objectId', expertRegions[i].id)
								.limit(1)
								.find()
								.then(function(regionObject) {
									$scope.jsonRegionArrayList.push({
										regionName: regionObject[0].get('regionName'),
									})
								})
						}
					}

					if (
						UserprofileObject[0].get('accountName') ||
						UserprofileObject[0].get('accountNumber') ||
						UserprofileObject[0].get('paypalAccount')
					) {
						$scope.accountDetails = true
					}
				}
				$scope.spinnerprofile = false
			})
	},
])
