
angular.module('ParseServices').factory('SnappService', [
	function() {
		const snappYourIdQuery = (type, page = 0) => {
			const limit = 40
			const snapp = Parse.Object.extend('Snapp')
			const snappInfoQuery = new Parse.Query(snapp)
			snappInfoQuery
				.notEqualTo('sendForIdentification', null)
				.equalTo('expert', Parse.User.current())
				.include('snappUser')
				.include('region')
				.limit(limit)
				.skip(page * limit)

			if (type === 'response') {
				snappInfoQuery.equalTo('userCommentStatus', true)
				snappInfoQuery.limit(1000)
			}

			if (type === 'myId') {
				snappInfoQuery.notEqualTo('userCommentStatus', true)
			}

			snappInfoQuery.descending('createdAt')

			return snappInfoQuery
		}

		const getSnappDetails = snappObject => {
			const snappRegionObject = snappObject.get('region')
			const snappUserObject = snappObject.get('snappUser')

			const sendForIdentification = new Date(
				snappObject.get('sendForIdentification').toUTCString()
			)

			// const updatedAt = new Date(snappObject.get('createdAt').toUTCString())

			let regionName = null
			let firstName = null
			let lastName = null
			let email = null
			let subscriptionStatus = null

			if (snappRegionObject) {
				regionName = snappRegionObject.get('regionName')
			}

			if (snappUserObject) {
				firstName = snappUserObject.get('firstName')
				lastName = snappUserObject.get('lastName')
				email = snappUserObject.get('email')
				subscriptionStatus = snappUserObject.get('subscriptionStatus')
			}

			return {
				regionName: regionName,
				firstName: firstName,
				lastName: lastName,
				email: email,
				subscriptionStatus: subscriptionStatus,
				sendForIdentification: sendForIdentification.getTime(),
			}
		}

		return {
			getMySnapp: (scope, page = 0) => {
				const snappInfoQuery = snappYourIdQuery('myId', page)
				snappInfoQuery.find().then(function(mySnappObject) {
					if (mySnappObject.length > 0) {
						mySnappObject.forEach(plant => {
							const snappResult = getSnappDetails(plant)
							scope.mySnapp.push({
								thumbImage: plant.get('thumbImage'),
								objectId: plant.id,
								snappType: plant.get('snappType'),
								userCommentStatus: plant.get('userCommentStatus'),
								regionName: snappResult.regionName,
								firstName: snappResult.firstName,
								lastName: snappResult.lastName,
								email: snappResult.email,
								createdAt: snappResult.sendForIdentification,
								subscriptionStatus: snappResult.subscriptionStatus,
								type: 'mySnapp',
								swipeNextPreviousPath: '/Response',
								box: 'local',
							})
						})

						if (mySnappObject.length === 1000) {
							mySnappObject = mySnappObject[mySnappObject.length - 1]
							scope.localMyIdEmptyMessage = ''
							this.getMySnapp()
						}
					} else {
						scope.loadMoreSnappsButton = false
					}

					scope.spinnerMyId = false

					if (scope.mySnapp.length === 0) {
						scope.localMyIdEmptyMessage = 'Empty'
					}

					return scope.mySnapp
				})
			},
			getResponseSnapp: scope => {
				scope.localResponseEmptyMessage = ''

				const snappInfoQuery = snappYourIdQuery('response')
				snappInfoQuery.find().then(function(responseSnappObject) {
					if (responseSnappObject.length > 0) {
						responseSnappObject.forEach(plant => {
							const snappResult = getSnappDetails(plant)
							scope.responseSnapp.push({
								thumbImage: plant.get('thumbImage'),
								objectId: plant.id,
								snappType: plant.get('snappType'),
								userCommentStatus: plant.get('userCommentStatus'),
								regionName: snappResult.regionName,
								firstName: snappResult.firstName,
								lastName: snappResult.lastName,
								email: snappResult.email,
								createdAt: snappResult.sendForIdentification,
								subscriptionStatus: snappResult.subscriptionStatus,
								type: 'response',
								swipeNextPreviousPath: '/Response',
								box: 'local',
							})
						})

						if (responseSnappObject.length === 1000) {
							responseSnappObject =
								responseSnappObject[responseSnappObject.length - 1]
							scope.localResponseEmptyMessage = ''
							this.getResponseSnapp()
						}
					}
					scope.spinnerResponse = false

					if (scope.responseSnapp.length === 0) {
						scope.localResponseEmptyMessage = 'Empty'
					}

					scope.$apply()

					return scope.responseSnapp
				})
			},
			getLocalSnapp: (scope, query, lastSnappDate, page) => {
				const snappInfoQuery = query('localPlant', lastSnappDate, page)

				snappInfoQuery.find().then(function(snappObjectLocal) {
					if (snappObjectLocal.length > 0) {
						if (typeof page === 'undefined') {
							scope.localSnappPlant = []
						}

						snappObjectLocal.forEach(plant => {
							const snappResult = getSnappDetails(plant)
							scope.localSnappPlant.push({
								thumbImage: plant.get('thumbImage'),
								objectId: plant.id,
								snappType: plant.get('snappType'),
								regionName: snappResult.regionName,
								firstName: snappResult.firstName,
								lastName: snappResult.lastName,
								email: snappResult.email,
								createdAt: moment(snappResult.sendForIdentification).fromNow(),
								subscriptionStatus: snappResult.subscriptionStatus,
								type: 'local',
								swipeNextPreviousPath: '/Identify',
								box: 'local',
							})
						})

						if (snappObjectLocal.length === 1000) {
							(snappObjectLocal =
								snappObjectLocal[snappObjectLocal.length - 1]),
							(lastSnappDate = snappObjectLocal.createdAt)

							scope.localPlantEmptyMessage = ''
							this.getLocalSnapp(lastSnappDate)
						}
					} else {
						scope.loadMoreLocalPlantsButton = false
						scope.loadAllLocalPlantsButton = false
					}

					scope.spinnerLocalPlant = false

					if (scope.localSnappPlant.length === 0) {
						scope.localPlantEmptyMessage = 'Empty'
					}

					scope.$apply()
				})
			},
			getLocalSnappPest: (scope, query, lastSnappDate, page) => {
				scope.localPestEmptyMessage = ''
				const snappInfoQuery = query('localPest', lastSnappDate, page)

				snappInfoQuery.find().then(function(snappObjectLocal) {
					if (snappObjectLocal.length > 0) {
						for (const snapp of snappObjectLocal) {
							const snappResult = getSnappDetails(snapp)
							scope.localSnappPests && scope.localSnappPests.push({
								thumbImage: snapp.get('thumbImage'),
								objectId: snapp.id,
								snappType: snapp.get('snappType'),
								regionName: snappResult.regionName,
								firstName: snappResult.firstName,
								lastName: snappResult.lastName,
								email: snappResult.email,
								createdAt: moment(snappResult.sendForIdentification).fromNow(),
								subscriptionStatus: snappResult.subscriptionStatus,
								type: 'localPest',
								swipeNextPreviousPath: '/Identify',
								box: 'local',
							})
						}

						if (snappObjectLocal.length === 1000) {
							(snappObjectLocal =
								snappObjectLocal[snappObjectLocal.length - 1]),
							(lastSnappDate = snappObjectLocal.createdAt)
							this.getLocalSnappPest(scope, query, lastSnappDate)
						}
					} else {
						scope.loadMoreLocalPlantsButton = false
						scope.loadAllLocalPlantsButton = false
					}

					scope.spinnerlocalPest = false

					if (scope.localSnappPests && scope.localSnappPests.length === 0) {
						scope.localPestEmptyMessage = 'Empty'
					}

					scope.$apply()
				})
			},
			getMyFolderSnapp: (scope, query, lastSnappDate) => {
				const snappInfoQuery = query('myFolder', lastSnappDate)
				scope.myFolderEmptyMessage = ''
				snappInfoQuery.find().then(function(snappObjectMyFolder) {
					if (snappObjectMyFolder.length > 0) {
						snappObjectMyFolder.forEach(plant => {
							const snappResult = getSnappDetails(plant)

							scope.myFolderSnapp.push({
								thumbImage: plant.get('thumbImage'),
								snappType: plant.get('snappType'),
								objectId: plant.id,
								regionName: snappResult.regionName,
								firstName: snappResult.firstName,
								lastName: snappResult.lastName,
								email: snappResult.email,
								createdAt: snappResult.sendForIdentification,
								subscriptionStatus: snappResult.subscriptionStatus,
								type: 'myFolder',
								swipeNextPreviousPath: '/Identify',
								box: 'local',
							})
						})

						if (snappObjectMyFolder.length === 1000) {
							(snappObjectMyFolder =
								snappObjectMyFolder[snappObjectMyFolder.length - 1]),
							(lastSnappDate = snappObjectMyFolder.createdAt)
							this.getMyFolderSnapp(lastSnappDate)
						}
					}

					scope.spinnerMyFolder = false

					if (scope.myFolderSnapp.length === 0) {
						scope.myFolderEmptyMessage = 'Empty'
					}

					scope.$apply()
				})
			},
			getNewChats: (scope, query, lastSnappDate, page) => {
				const snappInfoQuery = query('newChats', lastSnappDate, page)
				scope.plantChatsEmptyMessage = ''
				snappInfoQuery.find().then(function(snappObjectPlantChats) {
					if (snappObjectPlantChats.length > 0) {
						snappObjectPlantChats.forEach(snapp => {
							const snappResult = getSnappDetails(snapp)

							scope.newChats.push({
								thumbImage: snapp.get('thumbImage'),
								snappType: snapp.get('snappType'),
								objectId: snapp.id,
								regionName: snappResult.regionName,
								firstName: snappResult.firstName,
								lastName: snappResult.lastName,
								email: snappResult.email,
								createdAt: moment(snappResult.sendForIdentification).fromNow(),
								subscriptionStatus: snappResult.subscriptionStatus,
								type: 'newChat',
								swipeNextPreviousPath: '/Identify',
								box: 'local',
							})
						})
					}

					scope.spinnerNewChats = false

					if (scope.myFolderSnapp.length === 0) {
						scope.plantChatsEmptyMessage = 'Empty'
					}

					scope.$apply()
				})
			},
			getReopenedChats: (scope, query, lastSnappDate, page) => {
				const snappInfoQuery = query('reopenedChats', lastSnappDate, page)
				scope.reopenedChatsEmptyMessage = ''
				snappInfoQuery.find().then((snappObjectReopenedChats) => {
					if (snappObjectReopenedChats.length > 0) {
						for (const snapp of snappObjectReopenedChats) {
							const snappResult = getSnappDetails(snapp)

							scope.reopenedChats.push({
								thumbImage: snapp.get('thumbImage'),
								snappType: snapp.get('snappType'),
								objectId: snapp.id,
								regionName: snappResult.regionName,
								firstName: snappResult.firstName,
								lastName: snappResult.lastName,
								email: snappResult.email,
								sendForIdentification: moment(
									snappResult.sendForIdentification
								).fromNow(),
								updatedAt: moment(snappResult.createdAt).fromNow(),
								subscriptionStatus: snappResult.subscriptionStatus,
								type: 'reopenedChat',
								swipeNextPreviousPath: '/Identify',
								box: 'local',
							})
						}
					}

					scope.spinnerReopenedChats = false

					if (scope.myFolderSnapp.length === 0) {
						scope.reopenedChatsEmptyMessage = 'Empty'
					}

					scope.$apply()
				})
			},
			getMysterySnappPlant: (scope, query, lastSnappDate, page) => {
				const snappInfoQuery = query('mysteryPlant', lastSnappDate, page)
				scope.mysteryPlantEmptyMessage = ''

				snappInfoQuery.find().then(snappObjectMystery => {
					if (snappObjectMystery.length > 0) {
						for (const plant of snappObjectMystery) {
							const snappResult = getSnappDetails(plant)

							scope.mysterySnappPlant.push({
								thumbImage: plant.get('thumbImage'),
								snappType: plant.get('snappType'),
								objectId: plant.id,
								regionName: snappResult.regionName,
								firstName: snappResult.firstName,
								lastName: snappResult.lastName,
								email: snappResult.email,
								createdAt: moment(
									snappResult.sendForIdentification
								).fromNow(),
								subscriptionStatus: snappResult.subscriptionStatus,
								type: 'mysteryPlant',
								swipeNextPreviousPath: '/Identify',
								box: 'mystery',
							})
						}

						if (snappObjectMystery.length === 1000) {
							(snappObjectMystery =
								snappObjectMystery[snappObjectMystery.length - 1]),
							(lastSnappDate = snappObjectMystery.createdAt)

							this.getMysterySnappPlant(lastSnappDate)
						}
					} else {
						scope.loadMoreMysteryPlantsButton = false
					}
					scope.spinnerMysteryPlant = false

					if (scope.mysterySnappPlant.length === 0) {
						scope.mysteryPlantEmptyMessage = 'Empty'
					}

					scope.$apply()
				})
			},
			getMysterySnappPest: (scope, query, lastSnappDate, page) => {
				var snappInfoQuery = query('mysteryPest', lastSnappDate, page)
				scope.mysteryPestEmptyMessage = ''

				snappInfoQuery.find().then(function(snappObjectMysteryPest) {
					if (snappObjectMysteryPest.length > 0) {
						for (const plant of snappObjectMysteryPest) {
							const snappResult = getSnappDetails(plant)

							scope.mysterySnappPest.push({
								thumbImage: plant.get('thumbImage'),
								snappType: plant.get('snappType'),
								objectId: plant.id,
								regionName: snappResult.regionName,
								firstName: snappResult.firstName,
								lastName: snappResult.lastName,
								email: snappResult.email,
								createdAt: moment(
									snappResult.sendForIdentification
								).fromNow(),
								subscriptionStatus: snappResult.subscriptionStatus,
								type: 'mysteryPest',
								swipeNextPreviousPath: '/Identify',
								box: 'mystery',
							})
						}

						if (snappObjectMysteryPest.length === 1000) {
							(snappObjectMysteryPest =
								snappObjectMysteryPest[snappObjectMysteryPest.length - 1]),
							(lastSnappDate = snappObjectMysteryPest.createdAt)
							this.getMysterySnappPest(lastSnappDate)
						}
					} else {
						scope.loadMoreMysteryPestsButton = false
					}

					scope.spinnerMysteryPest = false

					if (scope.mysterySnappPest.length === 0) {
						scope.mysteryPestEmptyMessage = 'Empty'
					}

					scope.$apply()
				})
			},
		}
	},
])
