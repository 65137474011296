angular
	.module('PSGCExpertApp', [
		'ngCookies',
		'ngSanitize',
		'ParseServices',
		'ngRoute',
		'angular-growl',
	])

	.config(function($routeProvider, $locationProvider) {
		$routeProvider

			.when('/', {
				templateUrl: 'views/login.html',
				controller: 'MainCtrl',
			})

			.when('/home/:id?', {
				templateUrl: 'views/home.html',
				controller: 'DashboardCtrl',
			})

			.when('/mystery-box/:id?', {
				templateUrl: 'views/home.html',
				controller: 'DashboardCtrl',
			})

			.when('/expertIDsToDate', {
				templateUrl: 'views/expertIDsToDate.html',
				controller: 'ExpertIDsToDateCtrl',
			})

			.when('/faq', {
				templateUrl: 'views/faq.html',
				controller: 'FaqCtrl',
			})

			.when('/identifyPlant/:id/:type/:category?/:box', {
				templateUrl: 'views/identifyPlant.html',
				controller: 'IdentifyPlantCtrl',
			})

			.when('/profile', {
				templateUrl: 'views/profile.html',
				controller: 'ProfileCtrl',
			})

			.when('/profileView', {
				templateUrl: 'views/profileView.html',
				controller: 'ProfileViewCtrl',
			})

			.when('/articleList', {
				templateUrl: 'views/articleList.html',
				controller: 'ArticleListCtrl',
			})

			.when('/articleAdd/:id?', {
				templateUrl: 'views/articleAdd.html',
				controller: 'ArticleAddCtrl',
			})

			.when('/messaging', {
				templateUrl: 'views/messaging.html',
				controller: 'MessagingCtrl',
			})

			.when('/rooms/:id?', {
				templateUrl: 'views/room.html',
				controller: 'RoomCtrl',
			})

			.otherwise({
				redirectTo: '/',
			})

		$locationProvider.html5Mode({
			enabled: true,
			requireBase: false,
		})
	})
	.run([
		'ParseSDK',
		'$rootScope',
		'$location',
		'$route',
		'Auth',
		'growl',
		function(ParseServices, $rootScope, $location, $route, Auth, growl) {
			//parse instantiated throught service injection
			$rootScope.$on('$locationChangeStart', function(event) {
				var str = $location.$$url

				$rootScope.currentPage = 0
				$rootScope.itemsPerPage = 10
				$rootScope.totalCount = null

				if (str === '/' && Auth.isLoggedIn()) {
					$location.path('/home')
				}
				if (Auth.isLoggedIn() == false) {
					console.log('DENY')
					$location.path('/')
				}
			})

			$rootScope.safeApply = function(fn) {
				var phase

				if (this.$root != null) {
					phase = this.$root.$$phase
				}

				if (phase == '$apply' || phase == '$digest') {
					this.$eval(fn)
				} else {
					this.$apply(fn)
				}
			}

			$rootScope.getClass = function(path) {
				if ($location.path().substr(0, path.length) == path) {
					return 'current'
				} else {
					return ''
				}
			}

			$rootScope.basicAlert = function(type) {
				var config = {}
				switch (type) {
				case 'success':
					growl.success('Record added successfully', config)
					break
				case 'update':
					growl.success('Record updated successfully', config)
					break
				case 'info':
					growl.info('Record Already Added', config)
					break
				case 'delete':
					growl.info('Record Delete successfully', config)
					break
				case 'change-status':
					growl.info('Change status successfully', config)
					break

				case 'expert-success':
					growl.info(
						'New Expert added successfully and an email with username and password has been sent to the expert',
						config
					)
					break
				default:
					growl.error('Ups, error message here!', config)
				}
			}
		},
	])
	.directive('myMaxlength', function() {
		return {
			require: 'ngModel',
			link: function(scope, element, attrs, ngModelCtrl) {
				var maxlength = Number(attrs.myMaxlength)

				function fromUser(text) {
					if (text.length > maxlength) {
						var transformedInput = text.substring(0, maxlength)
						ngModelCtrl.$setViewValue(transformedInput)
						ngModelCtrl.$render()
						return transformedInput
					}
					return text
				}

				ngModelCtrl.$parsers.push(fromUser)
			},
		}
	})
	.directive('cleditor', function() {
		return {
			require: '?ngModel',
			link: function(scope, elm, attr, ngModel) {
				if (!ngModel) return

				ngModel.$render = function() {
					elm.val(ngModel.$viewValue).blur()
				}
				elm.cleditor().change(function() {
					var value = elm.val()

					if (!scope.$$phase) {
						scope.$apply(function() {
							ngModel.$setViewValue(value)
						})
					}
				})
			},
		}
	})
