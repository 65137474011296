
angular.module('PSGCExpertApp').controller('ExpertIDsToDateCtrl', [
	'$scope',
	'$interval',
	'SnappService',
	function($scope, $interval, SnappService) {
		// This is for toggle status
		$scope.toggleLocalPlant = true
		$scope.toggleMysteryPlant = true
		$scope.loadMoreSnappsButton = true
		$scope.mySnappsPage = 0

		$scope.spinnerMyId = true

		$scope.mySnapp = []

		$scope.toggleLocalPlantFun = function() {
			$scope.toggleLocalPlant = $scope.toggleLocalPlant === false
		}

		$scope.toggleMysteryPlantFun = function() {
			$scope.toggleMysteryPlant = $scope.toggleMysteryPlant === false
		}

		// Get identified snapp for individual expert.
		SnappService.getMySnapp($scope, $scope.mySnappsPage)

		$scope.loadMoreMySnapps = function() {
			$scope.spinnerMyId = true
			$scope.mySnappsPage = ++$scope.mySnappsPage
			SnappService.getMySnapp($scope, $scope.mySnappsPage)
		}

		$scope.callAtInterval = function() {
			$scope.responseSnapp = []
			$scope.spinnerResponse = true
			SnappService.getResponseSnapp($scope)
		}

		$interval(function() {
			$scope.callAtInterval()
		}, 900000)
		$scope.callAtInterval()
	},
])
