
angular.module('PSGCExpertApp').controller('ProfileCtrl', [
	'$scope',
	'$rootScope',
	'$location',
	function($scope, $rootScope, $location) {
		$scope.spinnerprofile = true
		var regionArray = []

		$scope.getRegionDetails = function(status) {
			var regionTable = Parse.Object.extend('Region')
			var getRegionQuery = new Parse.Query(regionTable)
			getRegionQuery.ascending('regionName').limit(200)
			getRegionQuery.find().then(function(regionObject) {
				if (regionObject.length > 0) {
					$scope.jsonRegionArrayList = []
					for (var i = 0; i < regionObject.length; i++) {
						$scope.jsonRegionArrayList.push({
							regionName: regionObject[i].get('regionName'),
							objectId: regionObject[i].id,
						})
					}

					$scope.spinnerProfile = true
					$(document).ready(function() {
						$('#region').select2({
							placeholder: 'Select a region',
						})
					})
					getExpertDetails()
				}
			})
		}

		$scope.getRegionDetails()
		function getExpertRegionDetails(expertRegions) {
			if (expertRegions) {
				$scope.spinnerprofile = true
				var expertSelectRegionObject = []
				var increment = 0
				for (var i = 0; i < expertRegions.length; i++) {
					$scope.expertRegions = true
					var regionTable = Parse.Object.extend('Region')
					var getRegionQuery = new Parse.Query(regionTable)
					getRegionQuery
						.equalTo('objectId', expertRegions[i].id)
						.limit(1)

						.find()
						.then(function(regionObject) {
							expertSelectRegionObject.push(regionObject[0].id)
							increment++
							if (increment === expertRegions.length) {
								$('#region').select2('val', expertSelectRegionObject)
								$scope.spinnerprofile = false
							}
						})
				}
			}
		}

		function getExpertDetails() {
			var expertProfile = Parse.Object.extend('ExpertDetails')
			var getProfileQuery = new Parse.Query(expertProfile)
			getProfileQuery
				.equalTo('user', Parse.User.current())
				.limit(1)
				.include('region')

				.find()
				.then(function(UserprofileObject) {
					$scope.profileObject = UserprofileObject.id

					if (UserprofileObject.length > 0) {
						var expertRegions = UserprofileObject[0].get('expertRegions')
						getExpertRegionDetails(expertRegions)
						var regionObject = UserprofileObject[0].get('region')
						if (regionObject) {
							var regionObjectId = regionObject.id
						}

						$scope.form = {
							objectId: UserprofileObject[0],
							accountName: UserprofileObject[0].get('accountName'),
							accountNumber: UserprofileObject[0].get('accountNumber'),
							job: UserprofileObject[0].get('job'),
							paypalAccount: UserprofileObject[0].get('paypalAccount'),
							phoneNumber: UserprofileObject[0].get('phoneNumber'),
							firstName: $rootScope.firstName,
							lastName: $rootScope.lastName,
							region: UserprofileObject[0].get('expertRegions'),
						}
					} else {
						$scope.form = {
							objectId: 'Null',
						}
					}
					$scope.spinnerprofile = false
				})
		}

		var redirect = function() {
			$scope.safeApply(function() {
				$scope.spinnerprofile = false
				alert('User details update successfully')
				$location.path('profileView')
				console.log('User details update success')
			})
		}

		$scope.updateExpertRegion = function(formData) {
			var regionArray = []
			for (var i = 0; i < $('#region').select2('data').length; i++) {
				regionArray.push($('#region').select2('data')[i].id)
			}

			if (formData.firstName || formData.lastName || formData.region) {
				Parse.Cloud.run('updateUserDetails', {
					objectId: Parse.User.current().id,
					firstName: formData.firstName,
					lastName: formData.lastName,
					region: regionArray,
				})
					.then(result => {
						console.log('calling cloud code')
						redirect()
					})
					.catch(error => {
						alert(JSON.stringify(error))
						alert('User details update failed')
					})
			} else {
				redirect()
			}
		}

		// Update the text box value by calling the submit form.
		$scope.submitForm = function() {
			var formData = $scope.form
			$scope.spinnerprofile = true
			if ($scope.profileform.$valid) {
				for (var i = 0; i < $('#region').select2('data').length; i++) {
					regionArray.push($('#region').select2('data')[i].id)
				}

				var expertProfile = Parse.Object.extend('ExpertDetails')
				var userProfile = new expertProfile()
				userProfile.id = formData.objectId.id
				userProfile.set('accountName', formData.accountName)
				userProfile.set('paypa]lAccount', formData.paypalAccount)
				userProfile.set('accountNumber', formData.accountNumber)
				userProfile.set('job', formData.job)
				userProfile.set('phoneNumber', formData.phoneNumber)
				userProfile
					.save(null)
					.then(savedObject => {
						if (formData.region.length > 0) {
							var region = Parse.Object.extend('Region')
							var getRegionQuery = new Parse.Query(region)
							getRegionQuery
								.containedIn('objectId', regionArray)
								.find()
								.then(function(regionObject) {
									savedObject.set('expertRegions', regionObject)
									savedObject.save()
									$scope.updateExpertRegion(formData)
								})
						} else {
							$scope.updateExpertRegion(formData)
						}
					})
					.catch(error => {
						alert('This is the error message :' + error.message)
						$scope.addArticleSpinner = false
					})
			} else {
				$scope.safeApply(function() {
					$scope.spinnerprofile = false
					alert('Fill all the fields')
				})
			}
			event.preventDefault()
		}
	},
])
