
angular.module('PSGCExpertApp').controller('MainCtrl', [
	'$scope',
	'$rootScope',
	'$location',
	'Auth',
	'$interval',
	function($scope, $rootScope, $location, Auth, $interval) {
		$scope.logout = function() {
			$rootScope.userLogin = false
			Parse.User.logOut()
			$location.path('/')
		}

		function handleError($scope, error) {
			$scope.safeApply(function() {
				$scope.spinner = false
				alert('Error: ' + error.message)
			})
		}

		function snappUserCommentsCount() {
			var snapp = Parse.Object.extend('Snapp')
			var snappInfoQuery = new Parse.Query(snapp)
			var snappInfoQuery2 = new Parse.Query(snapp)
			snappInfoQuery.notEqualTo('sendForIdentification', null)
			snappInfoQuery.equalTo('expert', Parse.User.current())
			snappInfoQuery.equalTo('userCommentStatus', true)
			snappInfoQuery
				.count()
				.then(responsecount => {
					// The count request succeeded. Show the count
					$scope.safeApply(function() {
						$rootScope.snappResponseCount = responsecount
					})
				})
				.catch(error => {
					alert('Error: ' + error.message)
				})

			snappInfoQuery2.equalTo('expert', Parse.User.current())
			snappInfoQuery2.notEqualTo('sendForIdentification', null)
			snappInfoQuery2
				.count()
				.then(responsecount => {
					// The count request succeeded. Show the count
					$scope.safeApply(function() {
						$rootScope.allIDsCount = responsecount
					})
				})
				.catch(error => {
					alert('Error: ' + error.message)
				})
		}

		if (Auth.isLoggedIn()) {
			snappUserCommentsCount()
			var UserProfile = Parse.Object.extend('_User')
			var query = new Parse.Query(UserProfile)
			query.equalTo('objectId', Parse.User.current().id)
			query.find().then(function(UserProfiledata) {
				$rootScope.usrerprofilecount = UserProfiledata.length

				if (UserProfiledata.length > 0) {
					$rootScope.expertRegions = UserProfiledata[0].get('expertRegions')
					$rootScope.firstName = UserProfiledata[0].get('firstName')
					$rootScope.lastName = UserProfiledata[0].get('lastName')
					$rootScope.email = UserProfiledata[0].get('email')
				}
			})

			$rootScope.userLogin = true
			$rootScope.userObjectId = Parse.User.current()

			$rootScope.user_Info = {
				firstName: Parse.User.current().get('firstName'),
				lastName: Parse.User.current().get('lastName'),
				email: Parse.User.current().getEmail(),
			}

			if (Parse.User.current().getEmail() === 'admin@gmail.com') {
				$scope.logout()
			}
		} else {
			$rootScope.userLogin = false
			$scope.login = function() {
				$scope.spinner = true

				Parse.User.logIn($scope.username, $scope.password)
					.then(user => {
						var queryRole = new Parse.Query(Parse.Role)
						queryRole.equalTo('name', 'expert')
						queryRole
							.first()
							.then(result => {
								// Role Object
								var role = result
								var adminRelation = new Parse.Relation(role, 'users')
								var queryAdmins = adminRelation.query()
								queryAdmins.equalTo('objectId', Parse.User.current().id)
								queryAdmins.first().then(result => {
									$scope.safeApply(function() {
										if (result) {
											$scope.spinner = false
											$rootScope.userLogin = true
											Auth.setUser(result) //Update the state of the user in the app
											$location.path('home/local')
										} else {
											Parse.User.logOut()
											$scope.spinner = false
											$rootScope.userLogin = false
											alert('Sorry, only expert can access this!')
										}
									})
								})
							})
							.catch(error => {
								handleError($scope, error)
							})
					})
					.catch(error => {
						handleError($scope, error)
					})
				event.preventDefault()
			}

			$scope.forgot = function() {
				$scope.spinner = true
				Parse.User.requestPasswordReset($scope.forgotemail)
					.then(() => {
						$scope.safeApply(function() {
							$scope.spinner = false
							alert(
								'Your password reset request has been successful. Check your mailbox to reset the password.'
							)
							$('#forgotbox').hide()
							$('#loginbox').show()
						})
					})
					.catch(error => {
						handleError($scope, error)
					})
				event.preventDefault()
			}
		}
	},
])
