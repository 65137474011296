angular.module('PSGCExpertApp').controller('DashboardCtrl', [
	'$scope',
	'$interval',
	'$rootScope',
	'$routeParams',
	'$route',
	'$timeout',
	'$location',
	'SnappService',
	function(
		$scope,
		$interval,
		$rootScope,
		$routeParams,
		$route,
		$timeout,
		$location,
		SnappService
	) {
		var today = new Date(),
			lastMonthDate = new Date(today.getTime() - 30 * 24 * 3600 * 1000)

		// This is for toggle status
		if ($routeParams.id === null || $routeParams.id === undefined) {
			$routeParams.id = 'local'
		}

		$scope.id = $routeParams.id
		$scope.localPlantsPage = 0
		$scope.localPestsPage = 0
		$scope.newChatsPage = 0
		$scope.reopenedChatsPage = 0
		$scope.mysteryPlantsPage = 0
		$scope.mysteryPestsPage = 0
		$scope.loadMoreLocalPlantsButton = true
		$scope.loadAllLocalPlantsButton = true
		$scope.loadMoreLocalPestsButton = true
		$scope.loadAllLocalPestsButton = true
		$scope.loadMoreNewChatsButton = true
		$scope.loadAllNewChatsButton = true
		$scope.loadMoreReopenedChatsButton = true
		$scope.loadAllReopenedChatsButton = true
		$scope.loadMoreMysteryPlantsButton = true
		$scope.loadMoreMysteryPestsButton = true

		if ($routeParams.id === 'local') {
			$scope.local = true
			$scope.spinnerLocalPlant = true
			$scope.spinnerlocalPest = true
			$scope.spinnerMyFolder = true
			$scope.spinnerNewChats = true
			$scope.spinnerReopenedChats = true
			$scope.toggleLocalPlant = true
			$scope.toggleLocalPest = true
			$scope.toggleMyFolder = true
			$scope.toggleNewChat = true
			$scope.toggleReopenedChat = true
		}

		if ($routeParams.id === 'mystery') {
			$scope.mystery = true
			$scope.spinnerMysteryPlant = true
			$scope.spinnerMysteryPest = true
			$scope.toggleMysteryPlant = true
			$scope.toggleMysteryPest = true
		}

		var parseConfig,
			UserProfile = Parse.Object.extend('ExpertDetails'),
			query = new Parse.Query(UserProfile)

		query
			.equalTo('user', Parse.User.current())
			.find()
			.then(function(UserProfileData) {
				$rootScope.usrerprofilecount = UserProfileData.length
				if (UserProfileData.length > 0) {
					$scope.expertRegions = UserProfileData[0].get('expertRegions')

					Parse.Config.get().then(function(response) {
						parseConfig = response.attributes
						var d = new Date(),
							myFolderTime = parseConfig.myFolderTime,
							myFolderExpiryTime = myFolderTime * 3600 * 1000,
							myFolderExpiryDate = new Date(d.getTime() - myFolderExpiryTime),
							snapp = Parse.Object.extend('Snapp'),
							snappInfoQuery = new Parse.Query(snapp)
						snappInfoQuery
							.notEqualTo('sendForIdentification', null)
							.equalTo('expertIdentifiedDate', null)
							.equalTo('expertIdentifiedPlant', null)
							.equalTo('expert', null)
							.notEqualTo('assignedExpert', null)
							.lessThan('assignedExpertDate', myFolderExpiryDate)

							.find()
							.then(function(snapps) {
								_.each(snapps, function(snapp) {
									snapp.set('assignedExpert', null)
									snapp.set('assignedExpertDate', null)

									snapp.save()
								})

								$scope.callAtInterval()
							})
					})
				}
			})

		$scope.toggleLocalPlantFun = function() {
			$scope.toggleLocalPlant = $scope.toggleLocalPlant === false ? true : false
		}
		$scope.toggleMysteryPlantFun = function() {
			$scope.toggleMysteryPlant =
				$scope.toggleMysteryPlant === false ? true : false
		}
		$scope.toggleLocalPestFun = function() {
			$scope.toggleLocalPest = $scope.toggleLocalPest === false ? true : false
		}
		$scope.togggleMysteryPestFun = function() {
			$scope.toggleMysteryPest =
				$scope.toggleMysteryPest === false ? true : false
		}

		$scope.togggleMyFolderFun = function() {
			$scope.toggleMyFolder = $scope.toggleMyFolder === false ? true : false
		}

		$scope.toggleNewChatFun = function() {
			$scope.toggleNewChat = !$scope.toggleNewChat
		}

		$scope.toggleReopenedChatFun = function() {
			$scope.toggleReopenedChat = !$scope.toggleReopenedChat
		}

		$scope.loadMoreLocalSnappPlantDetails = function() {
			$scope.spinnerLocalPlant = true
			$scope.localPlantsPage = ++$scope.localPlantsPage
			SnappService.getLocalSnapp(
				$scope,
				snappParseQuery,
				null,
				$scope.localPlantsPage
			)
		}

		$scope.loadAllLocalSnappPlantDetails = function() {
			$scope.spinnerLocalPlant = true
			SnappService.getLocalSnapp($scope, snappParseQuery, null)
			$scope.loadAllLocalPlantsButton = false
			$scope.loadMoreLocalPlantsButton = false
		}

		$scope.loadMoreLocalSnappPestDetails = function() {
			$scope.spinnerLocalPest = true
			$scope.localPestsPage = ++$scope.localPestsPage
			SnappService.getLocalSnappPest(
				$scope,
				snappParseQuery,
				null,
				$scope.localPestsPage
			)
		}

		$scope.loadAllLocalSnappPestDetails = function() {
			$scope.spinnerLocalPest = true
			SnappService.getLocalSnappPest($scope, snappParseQuery, null)
			$scope.loadAllLocalPestsButton = false
			$scope.loadMoreLocalPestsButton = false
		}

		$scope.loadMoreNewChats = function() {
			$scope.spinnerNewChats = true
			$scope.newChatsPage = ++$scope.newChatsPage
			SnappService.getNewChats(
				$scope,
				snappParseQuery,
				null,
				$scope.newChatsPage
			)
		}

		$scope.loadMoreReopenedChats = function() {
			$scope.spinnerReopenedChats = true
			$scope.reopenedChatsPage = ++$scope.reopenedChatsPage
			SnappService.getReopenedChats(
				$scope,
				snappParseQuery,
				null,
				$scope.reopenedChatsPage
			)
		}

		$scope.loadMoreMysterySnappPlantDetails = function() {
			$scope.spinnerMysteryPlant = true
			$scope.mysteryPlantsPage = ++$scope.mysteryPlantsPage
			SnappService.getMysterySnappPlant(
				$scope,
				snappParseQuery,
				null,
				$scope.mysteryPlantsPage
			)
		}

		$scope.loadMoreMysterySnappPestDetails = function() {
			$scope.spinnerMysteryPest = true
			$scope.mysteryPestsPage = ++$scope.mysteryPestsPage
			SnappService.getMysterySnappPest(
				$scope,
				snappParseQuery,
				null,
				$scope.mysteryPestsPage
			)
		}

		$scope.callAtInterval = function() {
			$scope.localSnappPlant = []
			$scope.mysterySnappPlant = []
			$scope.localSnappPests = []
			$scope.mysterySnappPest = []
			$scope.myFolderSnapp = []
			$scope.newChats = []
			$scope.reopenedChats = []

			if ($routeParams.id === 'local') {
				$scope.local = true
				SnappService.getLocalSnapp($scope, snappParseQuery, lastMonthDate, 0)
				SnappService.getLocalSnappPest(
					$scope,
					snappParseQuery,
					lastMonthDate,
					0
				)
				SnappService.getMyFolderSnapp($scope, snappParseQuery, null)
				SnappService.getNewChats($scope, snappParseQuery, null, 0)
				SnappService.getReopenedChats($scope, snappParseQuery, null, 0)
			}

			if ($routeParams.id === 'mystery') {
				$scope.mystery = true
				SnappService.getMysterySnappPlant(
					$scope,
					snappParseQuery,
					lastMonthDate,
					0
				)
				SnappService.getMysterySnappPest(
					$scope,
					snappParseQuery,
					lastMonthDate,
					0
				)
			}
		}

		$interval(function() {
			$scope.callAtInterval()
		}, 900000)

		$scope.moveToFolder = function() {
			var checked = [],
				selectedSnapp = document.getElementsByName('selectedMysPlantSnapp')

			for (var k = 0; k < selectedSnapp.length; k++) {
				if (selectedSnapp[k].checked) {
					checked.push(selectedSnapp[k])
				}
			}

			if (checked.length > 0) {
				var confirmFlag = confirm(
					'Are you sure want to assign snapp to you folder'
				)

				if (confirmFlag) {
					for (var i = 0; i < checked.length; i++) {
						if (checked[i].value) {
							var snappTable = Parse.Object.extend('Snapp')
							var snappDetails = new snappTable()
							snappDetails.id = checked[i].value
							snappDetails.set('assignedExpert', Parse.User.current())
							snappDetails.save()
						}
					}
				}
				$route.reload()
			} else {
				alert('Please select a checkbox')
			}
		}

		$scope.checked = []

		$scope.unassignSnapps = function() {
			var selectedSnapp = document.getElementsByName('selectedMysPlantSnapp')
			$scope.checked = []

			for (var k = 0; k < selectedSnapp.length; k++) {
				if (selectedSnapp[k].checked) {
					$scope.checked.push(selectedSnapp[k])
				}
			}

			if ($scope.checked.length > 0) {
				var confirmFlag = confirm(
					'Are you sure want to unassign snapp from your folder?'
				)

				if (confirmFlag) {
					for (var i = 0; i < $scope.checked.length; i++) {
						if ($scope.checked[i].value) {
							var snappTable = Parse.Object.extend('Snapp')
							var snappDetails = new snappTable()
							snappDetails.id = $scope.checked[i].value
							snappDetails.set('assignedExpert', null)
							snappDetails.set('assignedExpertDate', null)
							snappDetails.save()
						}
					}

					$route.reload()
				} else {
					$route.reload()
				}
			} else {
				alert('Please select a checkbox')
			}
		}

		$scope.reassignSnapps = function() {
			var selectedSnapp = document.getElementsByName('selectedMysPlantSnapp')
			$scope.checked = []

			for (var k = 0; k < selectedSnapp.length; k++) {
				if (selectedSnapp[k].checked) {
					$scope.checked.push(selectedSnapp[k])
				}
			}

			if ($scope.checked.length > 0) {
				var confirmFlag = confirm(
					'Are you sure want to assign snapp to you folder'
				)

				if (confirmFlag) {
					$scope.modelOpenChangeStatus()
				} else {
					$route.reload()
				}
			} else {
				alert('Please select a checkbox')
			}
		}

		// snapp re-assign model
		$scope.modelOpenChangeStatus = function() {
			$('#changeStatus').modal('show')
		}

		$scope.getRegionDetails = function() {
			var regionTable = Parse.Object.extend('Region'),
				getRegionQuery = new Parse.Query(regionTable)

			getRegionQuery
				.ascending('regionName')
				.find()
				.then(function(regionObject) {
					if (regionObject.length > 0) {
						$scope.jsonRegionArrayList = []

						for (var i = 0; i < regionObject.length; i++) {
							$scope.jsonRegionArrayList.push({
								regionName: regionObject[i].get('regionName'),
								object: regionObject[i],
								objectId: regionObject[i].id,
							})
						}
						$scope.spinnerProfile = true
					}
				})
		}

		$scope.getRegionDetails()

		$scope.getExpertDetails = () => {
			var expertTable = Parse.Object.extend('ExpertDetails'),
				getExpertQuery = new Parse.Query(expertTable)

			getExpertQuery
				.include('user')
				.find()
				.then(expertObject => {
					if (expertObject.length > 0) {
						$scope.jsonExpertArrayList = []

						for (var i = 0; i < expertObject.length; i++) {
							var userObject = expertObject[i].get('user')
							if (userObject) {
								var firstName = userObject.get('firstName'),
									/* lastName = userObject.get('lastName'),
									email = userObject.get('email'), */
									userObjectId = userObject.id
							}

							$scope.jsonExpertArrayList.push({
								firstName: firstName,
								objectId: userObjectId,
							})
						}
					}
				})
		}

		$scope.getExpertDetails()

		$scope.assignedRegion = null
		$scope.assignedExpert = null
		$scope.assignComments = null

		$scope.assignSnappStatus = function() {
			$scope.safeApply(function() {
				$scope.assignChangeStatus = true
			})

			for (var i = 0; i < $scope.checked.length; i++) {
				if ($scope.checked[i].value) {
					if ($scope.assignedRegion !== null) {
						var regionClass = Parse.Object.extend('Region'),
							newRegionObject = new regionClass(),
							snappTable = Parse.Object.extend('Snapp'),
							snappDetails = new snappTable()

						newRegionObject.id = $scope.assignedRegion
						snappDetails.id = $scope.checked[i].value
						snappDetails.set('region', newRegionObject)
						snappDetails.save()

						$scope.redirect = true
					}

					if ($scope.assignComments !== null) {
						var snappTable = Parse.Object.extend('Snapp'),
							snappDetails = new snappTable()

						snappDetails.id = $scope.checked[i].value
						snappDetails.set(
							'assignedComments',
							'<b>' +
								Parse.User.current().get('firstName') +
								'</b> : ' +
								$scope.assignComments
						)

						snappDetails.save()
						$scope.redirect = true
					}

					$('#changeStatus').modal('hide')

					$scope.safeApply(function() {
						$scope.assignChangeStatus = false
					})
				}
			}

			if ($scope.assignedExpert !== null) {
				var UserProfile = Parse.Object.extend('_User'),
					query = new Parse.Query(UserProfile)

				query.equalTo('objectId', $scope.assignedExpert)

				query.find().then(UserProfileData => {
					if (UserProfileData.length > 0) {
						for (var i = 0; i < $scope.checked.length; i++) {
							if ($scope.checked[i].value) {
								var snappTable = Parse.Object.extend('Snapp'),
									snappDetails = new snappTable(),
									assignedUserEmailId = UserProfileData[0].get('email')

								snappDetails.id = $scope.checked[i].value
								snappDetails.set('assignedExpert', UserProfileData[0])
								snappDetails.save()
								var expertName = ''
								if (!Parse.User.current().get('firstName')) {
									expertName = 'Expert'
								} else {
									expertName = Parse.User.current().get('firstName')
								}

								Parse.Cloud.run(
									'sendEmailSnappAssignedExpert',
									{
										email: assignedUserEmailId,
										assignedBy: expertName,
									}
										.then(() => {
											console.log('Email Send to expert!')
										})
										.catch(error => {
											console.log('Email Sending expert fails' + error.message)
										})
								)
							}
						}
					}
				})

				$scope.redirect = true
			}

			if ($scope.redirect === true) {
				alert('Snapps reassigned successfully')
				$scope.redirectURL()
			}
		}

		$scope.redirectURL = function() {
			$timeout(callAtTimeout, 1000)

			function callAtTimeout() {
				$location.path('home')
			}
		}

		function snappParseQuery(type, lastSnappDate, page) {
			var d = new Date(),
				limit = 40,
				mysteryBoxTime = parseConfig.MYSTERY_BOX_TIME,
				snappExpiryTime = mysteryBoxTime * 3600 * 1000,
				snappExpiryDate = new Date(d.getTime() - snappExpiryTime),
				// pestExpiryTime = mysteryBoxTime * 3600 * 1000,
				// pestExpiryDate = new Date(d.getTime() - pestExpiryTime),
				snapp = Parse.Object.extend('Snapp')

			if (typeof page === 'undefined') {
				page = 0
				limit = 1000
			}

			var snappInfoQuery = Parse.Query.or(
				new Parse.Query(snapp).equalTo(
					'beingIdentifiedByExpert',
					Parse.User.current()
				),
				new Parse.Query(snapp).doesNotExist('beingIdentifiedByExpert')
			)

				.exists('sendForIdentification')
				.include('region')
				.include('snappUser')
				.ascending('createdAt')
				.skip(limit * page)
				.limit(limit)

			if (type === 'localPlant') {
				// local ids
				const now = new Date()
				snappInfoQuery = Parse.Query.or(
					new Parse.Query(snapp).equalTo(
						'beingIdentifiedByExpert',
						Parse.User.current()
					),
					new Parse.Query(snapp).doesNotExist('beingIdentifiedByExpert'),
					new Parse.Query(snapp)
						.exists('beingIdentifiedByExpert')
						.lessThan('beingIdentifiedUntilTime', now) // only show snapps that are currently being identified
				)
				snappInfoQuery
					.doesNotExist('expert')
					.doesNotExist('expertIdentifiedDate')
					.doesNotExist('assignedExpert')
					.doesNotExist('expertIdentifiedPlant')
					.containedIn('region', $scope.expertRegions)
					.equalTo('snappType', 'Plant')
					.descending('createdAt')
					.skip(limit * page)
					.limit(limit)
				//snappInfoQuery.greaterThanOrEqualTo("sendForIdentification", snappExpiryDate);
			} else if (type === 'mysteryPlant') {
				// mystery plants
				const now = new Date()
				snappInfoQuery = Parse.Query.or(
					new Parse.Query(snapp).equalTo(
						'beingIdentifiedByExpert',
						Parse.User.current()
					),
					new Parse.Query(snapp).doesNotExist('beingIdentifiedByExpert'),
					new Parse.Query(snapp)
						.exists('beingIdentifiedByExpert')
						.lessThan('beingIdentifiedUntilTime', now) // only show snapps that are currently being identified
				)
				snappInfoQuery
					.descending('sendForIdentification')
					.doesNotExist('expertIdentifiedDate')
					.doesNotExist('expert')
					.doesNotExist('assignedExpert')
					.doesNotExist('expertIdentifiedPlant')
					.lessThan('sendForIdentification', snappExpiryDate)
					.equalTo('snappType', 'Plant')
					.skip(limit * page)
					.limit(limit)
			} else if (type === 'localPest') {
				// pests
				const now = new Date()
				snappInfoQuery = Parse.Query.or(
					new Parse.Query(snapp).equalTo(
						'beingIdentifiedByExpert',
						Parse.User.current()
					),
					new Parse.Query(snapp).doesNotExist('beingIdentifiedByExpert'),
					new Parse.Query(snapp)
						.exists('beingIdentifiedByExpert')
						.lessThan('beingIdentifiedUntilTime', now) // only show snapps that are currently being identified
				)
				snappInfoQuery
					.doesNotExist('expert')
					.doesNotExist('expertIdentifiedDate')
					.doesNotExist('assignedExpert')
					.doesNotExist('expertIdentifiedPlant')
					.containedIn('region', $scope.expertRegions)
					.equalTo('snappType', 'PestAndDisease')
					.skip(limit * page)
					.limit(limit)
				// snappInfoQuery.greaterThanOrEqualTo("sendForIdentification", pestExpiryDate);
			} else if (type === 'mysteryPest') {
				// mystery pests
				const now = new Date()
				snappInfoQuery = Parse.Query.or(
					new Parse.Query(snapp).equalTo(
						'beingIdentifiedByExpert',
						Parse.User.current()
					),
					new Parse.Query(snapp).doesNotExist('beingIdentifiedByExpert'),
					new Parse.Query(snapp)
						.exists('beingIdentifiedByExpert')
						.lessThan('beingIdentifiedUntilTime', now) // only show snapps that are currently being identified
				)
				snappInfoQuery
					.descending('sendForIdentification')
					.doesNotExist('expertIdentifiedDate')
					.doesNotExist('expert')
					.doesNotExist('assignedExpert')
					.doesNotExist('expertIdentifiedPlant')
					.lessThan('sendForIdentification', snappExpiryDate)
					.equalTo('snappType', 'PestAndDisease')
					.limit(limit)
					.limit(limit)
			} else if (type === 'myFolder') {
				// 'my folder'
				snappInfoQuery
					.equalTo('expert', null)
					.equalTo('expertIdentifiedDate', null)
					.equalTo('assignedExpert', null)
					.equalTo('expertIdentifiedPlant', null)
					.equalTo('assignedExpert', Parse.User.current())
					.limit(1000)
			} else if (type === 'newChats') {
				// new chats
				snappInfoQuery
					.equalTo('userCommentStatus', true)
					.doesNotExist('isExpertCommentAdded')
					.descending('sendForIdentification')
					// plant chats are always about plants
					.exists('expertIdentifiedPlant')
					.skip(limit * page)
					.limit(limit)
			} else if (type === 'reopenedChats') {
				// reopened chats
				snappInfoQuery = new Parse.Query(snapp)
					.include('region')
					.include('snappUser')
					.descending('sendForIdentification')
					.equalTo('userCommentStatus', true)
					.exists('expertIdentifiedDate')
					.exists('isExpertCommentAdded')
					.skip(limit * page)
					.limit(limit)
			}

			if (lastSnappDate) {
				snappInfoQuery.greaterThan('createdAt', lastSnappDate)
			}

			return snappInfoQuery
		}

		$scope.changeOrderLocalPlants = function() {
			$scope.localSnappPlant.reverse()
		}

		$scope.changeOrderLocalPests = function() {
			$scope.localSnappPests.reverse()
		}
	},
])
