angular
	.module('ParseServices', [])

	.factory('ParseSDK', [
		'AppConfig',
		function(AppConfig) {
			Parse.initialize(AppConfig.appId, AppConfig.javascriptKey)
			Parse.serverURL = AppConfig.parseServerURL + AppConfig.parseUrlPath
			return null
		},
	])

	.factory('ParseQuery', [
		'$q',
		'$rootScope',
		function($q, $rootScope) {
			return function(query, options) {
				var defer = $q.defer()

				//default function call to find
				var functionToCall = 'find'
				if (options !== undefined && options.functionToCall !== undefined)
					functionToCall = options.functionToCall

				//wrap defer resolve/reject in $apply so angular updates watch listeners
				var defaultParams = [
					{
						success: function(data) {
							$rootScope.$apply(function() {
								defer.resolve(data)
							})
						},
						error: function(data, error) {
							//                                console.log('error:', error);
							$rootScope.$apply(function() {
								defer.reject(error)
							})
						},
					},
				]

				//check for additional parameters to add
				if (options && options.params)
					defaultParams = options.params.concat(defaultParams)

				query[functionToCall].apply(query, defaultParams)
				return defer.promise
			}
		},
	])

	.factory('ParseObject', [
		'ParseQuery',
		function(ParseQuery) {
			return function(parseData, fields) {
				//verify parameters
				if (parseData === undefined) throw new Error('Missing parseData')
				if (fields === undefined) throw new Error('Missing fields.')

				//internal parse object reference
				var parseObject = parseData

				//instantiate new parse object from string
				if (typeof parseData === 'string') {
					var ParseModel = Parse.Object.extend(parseData)
					parseObject = new ParseModel()
				}

				//expose underlying parse obejct through data property
				Object.defineProperty(this, 'data', {
					get: function() {
						return parseObject
					},
				})

				//add dynamic properties from fields array
				var self = this
				for (var i = 0; i < fields.length; i++) {
					//add closure
					(function() {
						var propName = fields[i]
						Object.defineProperty(self, propName, {
							get: function() {
								return parseObject.get(propName)
							},
							set: function(value) {
								parseObject.set(propName, value)
							},
						})
					})()
				}

				//instance methods
				this.save = function() {
					return ParseQuery(parseObject, {
						functionToCall: 'save',
						params: [null],
					})
				}

				this.delete = function() {
					return ParseQuery(parseObject, { functionToCall: 'destroy' })
				}

				this.fetch = function() {
					return ParseQuery(parseObject, { functionToCall: 'fetch' })
				}
			}
		},
	])

	.factory('CountQuery', [
		'$q',
		'$rootScope',
		function($q, $rootScope) {
			return {
				getCount: function(tableName, fieldName, fieldValue, constraints) {
					var countTable = Parse.Object.extend(tableName)
					var countInfoQuery = new Parse.Query(countTable)

					if (constraints === 'equalTo') {
						if (fieldName && fieldValue) {
							countInfoQuery.equalTo(fieldName, fieldValue)
						}
					} else if (constraints === 'notEqualTo') {
						if (fieldName && fieldValue) {
							countInfoQuery.notEqualTo(fieldName, fieldValue)
						}
					}

					countInfoQuery.count({
						success: function(responsecount) {
							$rootScope.safeApply(function() {
								return ($rootScope.totalCount = responsecount)
							})
						},
						error: function() {
							// console.log('Error: ' + error.message)
						},
					})
				},
			}
		},
	])

	.factory('LogoutService', function() {
		return {
			foo: function() {
				//                    alert("I'm foo!");
			},
		}
	})

	.factory('Auth', function() {
		return {
			setUser: function() {},
			isLoggedIn: function() {
				var user = Parse.User.current()
				return user ? user : false
			},
		}
	})

	.factory('Helpers', function() {
		return {
			/**
			 * Helper function to get the username from a given user
			 *
			 * @param {User} user
			 */
			getUsername: function(user) {
				const firstName = user.get('firstName')
				const lastName = user.get('lastName')
				if (firstName === undefined && lastName === undefined) {
					return user.getUsername()
				} else {
					let username =
						(firstName || '') +
						(firstName && lastName ? ' ' : '') +
						(lastName || '')
					return username
				}
			},
		}
	})
