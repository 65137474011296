angular.module('PSGCExpertApp').controller('ArticleAddCtrl', [
	'$scope',
	'$routeParams',
	'$rootScope',
	'$location',
	'$timeout',
	'$http',
	function($scope, $routeParams, $rootScope, $location, $timeout, $http) {
		$(document).ready(function() {
			$('#tags').select2({ tags: [] })
			var articleTagsTable = Parse.Object.extend('ArticleTags')
			var getArticleTagsQuery = new Parse.Query(articleTagsTable)
			getArticleTagsQuery.limit(1000)
			getArticleTagsQuery.find().then(function(articleTagsObject) {
				if (articleTagsObject.length > 0) {
					$scope.relatedArticleList = []
					for (var i = 0; i < articleTagsObject.length; i++) {
						$scope.relatedArticleList.push({
							id: articleTagsObject[i].id,
							text: articleTagsObject[i].get('tagName'),
						})
						if (i === articleTagsObject.length - 1) {
							$('#tags').select2({ tags: $scope.relatedArticleList })
						}
					}
				}
			})
		})

		var getTagsObject
		$scope.imageUploaded = false
		$scope.imageUploadedTwo = false
		$scope.imageUploadedThree = false

		if ($routeParams.id === undefined) {
			$scope.pageTitle = 'New Article'
			$scope.addArticleSpinner = false
		} else {
			var data = []
			$scope.addArticleSpinner = true
			$scope.pageTitle = 'Article Update'
			var articleTable = Parse.Object.extend('ExternalArticle')
			var getArticleQuery = new Parse.Query(articleTable)
			getArticleQuery
				.equalTo('objectId', $routeParams.id)
				.include('tags')
				.include('articlePhotos')
				.limit(1)
				.find()
				.then(function(articleObject) {
					$scope.safeApply(function() {
						if (articleObject.length > 0) {
							var articlePhotos = articleObject[0].get('articlePhotos')
							if (articlePhotos) {
								for (var i = 0; i < articlePhotos.length; i++) {
									if (i === 0) {
										$scope.articleImage = articlePhotos[i].get('thumbImage')
										$scope.imageUploaded = true
										$scope.imageOneObjectId = articlePhotos[i].id
									}
									if (i === 1) {
										$scope.articleImageTwo = articlePhotos[i].get('thumbImage')
										$scope.imageTwoObjectId = articlePhotos[i].id
										$scope.imageUploadedTwo = true
									}

									if (i === 2) {
										$scope.articleImageThree = articlePhotos[i].get(
											'thumbImage'
										)
										$scope.imageThreeObjectId = articlePhotos[i].id
										$scope.imageUploadedThree = true
									}
								}
							}

							getTagsObject = articleObject[0].get('tags')

							if (getTagsObject) {
								$scope.data = []
								for (var i = 0; i < getTagsObject.length; i++) {
									$scope.data.push({
										id: getTagsObject[i].id,
										text: getTagsObject[i].get('tagName'),
									})
									if (i === getTagsObject.length - 1) {
										$(document).ready(function() {
											$('#tags').select2('data', $scope.data)
										})
									}
								}
							}

							$scope.form = {
								title: articleObject[0].get('title'),
								content: articleObject[0].get('content'),
								tags: articleObject[0].get('tags'),
								type: articleObject[0].get('type'),
							}
						}
						$scope.addArticleSpinner = false
					})
				})
		}

		$scope.change = function(value) {
			$scope.addArticleSpinner = true
			console.log(value)
			var articlePhotoTable = Parse.Object.extend('ExternalArticlePhoto')
			var articlePhotoObject = new articlePhotoTable()
			articlePhotoObject.id = value
			articlePhotoObject.set('status', 'Deleted')
			articlePhotoObject
				.save(null)
				.then(articlePhotoObject => {
					var articleTable = Parse.Object.extend('ExternalArticle')
					var articleTable = new articleTable()
					articleTable.id = $routeParams.id
					articleTable.remove('articlePhotos', articlePhotoObject)
					articleTable.save()
					$scope.addArticleSpinner = false
				})
				.catch(error => {
					alert('This is the error message :' + error.message)
					$scope.addArticleSpinner = false
				})

			$scope.addArticleSpinner = false
		}

		var uploadArticleTags = function(
			getTagsObject,
			$scope,
			ArticleSavedObject,
			$routeParams
		) {
			var data = $('#tags').select2('data')
			if (getTagsObject) {
				for (var j = 0; j < getTagsObject.length; j++) {
					var resultToRemove = $scope.containsObjectRemove(
						getTagsObject[j].id,
						data
					)
					if (resultToRemove === false) {
						ArticleSavedObject.remove('tags', getTagsObject[j])
						ArticleSavedObject.save()
					}
				}
			}

			if (data.length > 0) {
				var data = $('#tags').select2('data')
				if (getTagsObject) {
					for (var j = 0; j < getTagsObject.length; j++) {
						var resultToRemove = $scope.containsObjectRemove(
							getTagsObject[j].id,
							data
						)
						if (resultToRemove === false) {
							ArticleSavedObject.remove('tags', getTagsObject[j])
							ArticleSavedObject.save()
						}
					}
				}

				if (data.length > 0) {
					var increment = 0

					for (var i = 0; i < data.length; i++) {
						var articleTagsTable = Parse.Object.extend('ArticleTags')
						var articleTagsTable = new articleTagsTable()

						if (data[i].id !== data[i].text) {
							articleTagsTable.id = data[i].id
						}
						articleTagsTable.set('tagName', data[i].text.toLowerCase())
						articleTagsTable.set('user', Parse.User.current())
						articleTagsTable
							.save(null)
							.then(tagsSaveObject => {
								ArticleSavedObject.add('tags', tagsSaveObject)
								ArticleSavedObject.save(null)
									.then(savedObject => {
										increment = increment + 1

										if (data.length === increment) {
											$scope.addArticleSpinner = false
											if ($routeParams.id !== undefined) {
												$scope.basicAlert('update')
												$scope.redirectURL()
											} else {
												$scope.basicAlert('success')
												$scope.redirectURL()
											}
										}
									})
									.catch(error => {
										alert('This is the error message :' + error.message)
									})
							})
							.catch(error => {
								alert('This is the error message :' + error.message)
								$scope.addArticleSpinner = false
							})
					}
				} else {
					$scope.basicAlert('success')
					$scope.redirectURL()
				}
			}
		}

		$scope.redirectURL = function() {
			$timeout(callAtTimeout, 3000)
			function callAtTimeout() {
				$location.path('articleList')
			}
		}

		$scope.containsObjectRemove = function(obj, list) {
			var i
			for (i = 0; i < list.length; i++) {
				if (angular.equals(list[i].id, obj)) {
					return true
				}
			}

			return false
		}

		$scope.addNewArticle = function(formData) {
			var ArticleSavedObject
			$scope.addArticleSpinner = true

			var articleTable = Parse.Object.extend('ExternalArticle')
			var articleDetails = new articleTable()

			if ($routeParams.id !== undefined) {
				articleDetails.id = $routeParams.id
			}

			articleDetails.set('title', formData.title)
			articleDetails.set('content', formData.content)
			articleDetails.set('user', Parse.User.current())
			articleDetails.set('status', 'Pending')

			articleDetails
				.save(null)
				.then(ArticleSavedObject => {
					var imageExist1 = document.getElementById('imageuploaded_1').value
					var imageExist2 = document.getElementById('imageuploaded_2').value
					var imageExist3 = document.getElementById('imageuploaded_3').value

					if (imageExist1 || imageExist2 || imageExist3) {
						var articlePhotoTable = Parse.Object.extend('ExternalArticlePhoto')
						var PhotoArray = []
						for (var i = 1; i <= 3; i++) {
							var articlePhotoDetails = new articlePhotoTable()
							var fileUploadControl = $('#imageuploaded_' + i)[0]
							var imageFile = fileUploadControl.files[0]
							if (imageFile) {
								var parseFile = new Parse.File('photo.jpg', imageFile)
								articlePhotoDetails.set('image', parseFile)
								PhotoArray.push(articlePhotoDetails)
							}
						}

						// save all the newly created objects
						Parse.Object.saveAll(PhotoArray)
							.then(objs => {
								for (i = 0; i < objs.length; i++) {
									ArticleSavedObject.add('articlePhotos', objs[i])
									ArticleSavedObject.save()
								}
								uploadArticleTags(
									getTagsObject,
									$scope,
									ArticleSavedObject,
									$routeParams
								)
							})
							.catch(error => {
								alert('error : ' + error)
							})
					} else {
						uploadArticleTags(
							getTagsObject,
							$scope,
							ArticleSavedObject,
							$routeParams
						)
					}
				})
				.catch(error => {
					console.log('This is the error message :' + error.message)
					$scope.addArticleSpinner = false
				})
		}

		function checkArticleAlreadyAdd(formData) {
			var plant = Parse.Object.extend('ExternalArticle')
			var query = new Parse.Query(plant)
			query.equalTo('title', formData.title)
			query
				.find({})
				.then(results => {
					if (results.length >= 1) {
						$scope.safeApply(function() {
							$scope.addArticleSpinner = false
						})
						alert('Article title name already exist.')
						return false
					} else {
						$scope.addNewArticle(formData)
					}
				})
				.catch(error => {
					$scope.addArticleSpinner = false
					alert('Error: ' + error.code + ' ' + error.message)
				})
		}

		$scope.submitForm = function() {
			if ($scope.ArticleForm.$valid) {
				$scope.addArticleSpinner = true
				var formData = $scope.form

				if ($routeParams.id === undefined) {
					checkArticleAlreadyAdd(formData)
				} else {
					$scope.addNewArticle(formData)
				}
			}
			event.preventDefault()
		}
	},
])
