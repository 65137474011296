angular.module('PSGCExpertApp').controller('MessagingCtrl', [
	'$scope',
	'$rootScope',
	'CountQuery',
	function($scope, $rootScope, CountQuery) {
		$scope.pageTitle = 'Article List'
		$scope.spinnerArticle = true
		$scope.snapps = []

		$scope.articleCount = CountQuery.getCount(
			'Room',
			'user',
			Parse.User.current(),
			'equalTo'
		)
		$scope.listQuery = function() {
			$scope.jsonArrayList = []
			$scope.spinnerArticle = true
			$scope.nextPageDisabled = false
			var snappQuery = new Parse.Query(Parse.Object.extend('Snapp'))
			snappQuery
				.include('snappUser')
				.include('expert')
				.include('expertIdentifiedPlant')
				.descending('created_at')
				.equalTo('expert', Parse.User.current())
				.limit($rootScope.itemsPerPage)
				.skip($rootScope.currentPage * $rootScope.itemsPerPage)
				.find()
				.then(function(snapps) {
					if (snapps.length > 0) {
						$scope.lastIndexValue =
							$rootScope.currentPage * $rootScope.itemsPerPage
						let snappsToShow = []
						for (const snapp of snapps) {
							$scope.lastIndexValue++
							let expertIdentifiedPlant = snapp.get('expertIdentifiedPlant')
							let heading =
								snapp.get('isSnappIdentified') && expertIdentifiedPlant
									? 'Plant identified as ' +
									  expertIdentifiedPlant.get('latinName')
									: 'Unidentified'
							let snappToShow = {
								title: snapp.id,
								heading,
								objectId: snapp.id,
								object: snapp,
								index: $scope.lastIndexValue,
								isSnappIdentified: snapp.get('isSnappIdentified'),
								createdAt: snapp.get('createdAt'),
								snappType: snapp.get('snappType'),
								image: snapp.get('thumbImage').url(),
								isExpertCommentAdded: snapp.get('isExpertCommentAdded'),
								unreadCommentsCount: snapp.get('unreadCommentsCount'),
								users: [],
							}
							if (expertIdentifiedPlant) {
								snappToShow.expertIdentifiedPlant = {
									name: expertIdentifiedPlant.get('latinName'),
									date: moment(snapp.get('expertIdentifiedDate')).fromNow(),
									timeToIdentify: moment(
										snapp.get('expertIdentifiedDate')
									).from(snapp.get('sendForIdentification'), true),
								}
							}
							const user = snapp.get('snappUser')
							if (user) {
								const firstName = user.get('firstName')
								const lastName = user.get('lastName')
								let username = ''
								if (firstName === undefined && lastName === undefined) {
									username = user.getUsername()
								} else {
									username =
										(firstName || '') +
										(firstName && lastName ? ' ' : '') +
										(lastName || '')
								}
								snappToShow.users.push({
									name: username,
								})
							}
							snappsToShow.push(snappToShow)
						}
						$scope.snapps = snappsToShow
						if ($rootScope.totalCount === $scope.lastIndexValue) {
							$scope.nextPageDisabled = true
						}
					} else {
						$scope.nextPageDisabled = true
						$scope.emptyMessageDisplay = true
					}

					$scope.spinnerArticle = false
					$scope.$apply()
				})
				.catch(function(err) {
					console.error(err)
				})
		}

		$scope.changeStatus = function(objectId, mode) {
			var sureDelete = confirm('Are you sure want to send for Approval')
			if (sureDelete === true) {
				$scope.spinnerArticle = true

				var externalTable = Parse.Object.extend('ExternalArticle')
				var externalObject = new externalTable()
				externalObject.id = objectId
				externalObject.set('status', 'Awaiting')
				externalObject
					.save(null)
					.then(result => {
						alert('send successfully')
						$scope.listQuery()
					})
					.catch(result => {
						response.error(error)
					})
			}
			$scope.spinnerArticle = false
		}

		// If user changes the list length (Drop Down).
		$scope.listLength = function(itemsPerPage) {
			$rootScope.itemsPerPage = itemsPerPage

			if ($scope.lastIndexValue > itemsPerPage) {
				$rootScope.currentPage = Math.floor(
					$scope.lastIndexValue / itemsPerPage
				)
			} else {
				$rootScope.currentPage = 0
			}
			$scope.listQuery()
		}

		$scope.prevPageDisabled = function() {
			return $scope.currentPage === 0 ? 'disabled' : ''
		}

		$scope.prevPage = function() {
			if ($rootScope.currentPage > 0) {
				$rootScope.currentPage--
				$scope.listQuery()
			}
		}

		$scope.nextPage = function() {
			if ($scope.nextPageDisabled !== true) {
				$rootScope.currentPage++
				$scope.listQuery()
			}
		}

		$scope.listQuery()
	},
])
