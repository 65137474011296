angular.module('PSGCExpertApp').controller('IdentifyPlantCtrl', [
	'$scope',
	'$rootScope',
	'$routeParams',
	'$interval',
	'$timeout',
	'$location',
	'SnappService',
	'Helpers',
	function(
		$scope,
		$rootScope,
		$routeParams,
		$interval,
		$timeout,
		$location,
		SnappService,
		Helpers
	) {
		const client = algoliasearch(
			'63VHWTO5WS',
			'03e41771a8465b720b1a026d5234a904'
		)
		const plantIndex = client.initIndex('plant')

		var snappUserObject
		$scope.canBeIdentified = true

		const lastMonthDate = new Date(new Date() - 30 * 24 * 3600 * 1000)
		let parseConfig

		$scope.snappId = $routeParams.id
		$scope.snappType = $routeParams.type
		$scope.category = $routeParams.category

		$scope.myFolderSnapp = []
		$scope.localSnappPlant = []
		$scope.localSnappPest = []
		$scope.responseSnapp = []
		$scope.mySnapp = []
		$scope.mysterySnappPlant = []
		$scope.mysterySnappPest = []
		$scope.mySnappsPage = 0
		$scope.currentUser = Parse.User.current()

		Parse.Config.get().then(response => {
			parseConfig = response.attributes
			SnappService.getMySnapp($scope)
			SnappService.getResponseSnapp($scope)
			SnappService.getMyFolderSnapp($scope, snappHomeAndMysteryQuery, null)
			SnappService.getLocalSnapp(
				$scope,
				snappHomeAndMysteryQuery,
				lastMonthDate,
				0
			)
			SnappService.getLocalSnappPest(
				$scope,
				snappHomeAndMysteryQuery,
				lastMonthDate,
				0
			)
			SnappService.getMysterySnappPlant(
				$scope,
				snappHomeAndMysteryQuery,
				lastMonthDate,
				0
			)
			SnappService.getMysterySnappPest(
				$scope,
				snappHomeAndMysteryQuery,
				lastMonthDate,
				0
			)
		})

		const getCurrentSnapp = () => {
			const { box } = $routeParams
			const indexLocalSnapp = $scope.localSnappPlant.findIndex(
				snapp => snapp.objectId == $scope.snappId
			)
			const indexLocalSnappPest = $scope.localSnappPest.findIndex(
				snapp => snapp.objectId == $scope.snappId
			)
			const indexLocalMyFolder = $scope.myFolderSnapp.findIndex(
				snapp => snapp.objectId == $scope.snappId
			)
			const indexResponseSnapp = $scope.responseSnapp.findIndex(
				snapp => snapp.objectId == $scope.snappId
			)
			const indexMySnapp = $scope.mySnapp.findIndex(
				snapp => snapp.objectId == $scope.snappId
			)
			const indexMysteryPlant = $scope.mysterySnappPlant.findIndex(
				snapp => snapp.objectId == $scope.snappId
			)
			const indexMysteryPest = $scope.mysterySnappPest.findIndex(
				snapp => snapp.objectId == $scope.snappId
			)

			if (box == 'local') {
				if (indexLocalSnapp != -1) {
					return {
						type: 'local',
						index: indexLocalSnapp,
					}
				} else if (indexLocalSnappPest != -1) {
					return {
						type: 'localPest',
						index: indexLocalSnappPest,
					}
				} else if (indexLocalMyFolder != -1) {
					return {
						type: 'myFolder',
						index: indexLocalMyFolder,
					}
				} else if (indexResponseSnapp != -1) {
					return {
						type: 'response',
						index: indexResponseSnapp,
					}
				} else if (indexMySnapp != -1) {
					return {
						type: 'mySnapp',
						index: indexMySnapp,
					}
				}
			} else {
				if (indexMysteryPlant != -1) {
					return {
						type: 'mysteryPlant',
						index: indexMysteryPlant,
					}
				} else if (indexMysteryPest != -1) {
					return {
						type: 'mysteryPest',
						index: indexMysteryPest,
					}
				}
			}
		}

		const getPreviousSnapp = currentSnapp => {
			if (currentSnapp) {
				switch (currentSnapp.type) {
				case 'local':
					$scope.previousSnapp =
							$scope.localSnappPlant[currentSnapp.index - 1]
					break
				case 'localPest':
					$scope.previousSnapp = $scope.localSnappPest[currentSnapp.index - 1]
					break
				case 'myFolder':
					$scope.previousSnapp = $scope.myFolderSnapp[currentSnapp.index - 1]
					break
				case 'response':
					$scope.previousSnapp = $scope.responseSnapp[currentSnapp.index - 1]
					break
				case 'mySnapp':
					$scope.previousSnapp = $scope.mySnapp[currentSnapp.index - 1]
					break
				case 'mysteryPlant':
					$scope.previousSnapp =
							$scope.mysterySnappPlant[currentSnapp.index - 1]
					break
				case 'mysteryPest':
					$scope.previousSnapp =
							$scope.mysterySnappPest[currentSnapp.index - 1]
				}
			}
		}

		const getNextSnapp = currentSnapp => {
			if (currentSnapp) {
				switch (currentSnapp.type) {
				case 'local':
					$scope.nextSnapp = $scope.localSnappPlant[currentSnapp.index + 1]
					break
				case 'localPest':
					$scope.nextSnapp = $scope.localSnappPest[currentSnapp.index + 1]
					break
				case 'myFolder':
					$scope.nextSnapp = $scope.myFolderSnapp[currentSnapp.index + 1]
					break
				case 'response':
					$scope.nextSnapp = $scope.responseSnapp[currentSnapp.index + 1]
					break
				case 'mySnapp':
					$scope.nextSnapp = $scope.mySnapp[currentSnapp.index + 1]
					break
				case 'mysteryPlant':
					$scope.nextSnapp = $scope.mysterySnappPlant[currentSnapp.index + 1]
					break
				case 'mysteryPest':
					$scope.nextSnapp = $scope.mysterySnappPest[currentSnapp.index + 1]
				}
			}
		}

		const getEdgeSnapp = () => {
			getPreviousSnapp(getCurrentSnapp())
			getNextSnapp(getCurrentSnapp())
		}

		const interval = $interval(function() {
			getEdgeSnapp()
		}, 200)

		const setExpirationDateOfFlag = $interval(function() {
			updateBeingIdentifiedUntilTime()
		}, 1000 * 60)

		$timeout(function() {
			$interval.cancel(interval)
		}, 30000)

		function snappHomeAndMysteryQuery(type, lastSnappDate, page) {
			const today = new Date()
			const mysteryBoxTime = parseConfig.MYSTERY_BOX_TIME
			const snappExpiryDate = new Date(
				today.getTime() - mysteryBoxTime * 3600 * 1000
			)

			const expertRegions = $scope.currentUser.get('expertRegions')
			let limit = 40
			const snapp = Parse.Object.extend('Snapp')

			const snappInfoQuery = Parse.Query.or(
				new Parse.Query(snapp).equalTo(
					'beingIdentifiedByExpert',
					$scope.currentUser
				),
				new Parse.Query(snapp).doesNotExist('beingIdentifiedByExpert')
			)
				.notEqualTo('sendForIdentification', null)
				.equalTo('expertIdentifiedDate', null)
				.equalTo('expertIdentifiedPlant', null)
				.equalTo('expert', null)
				.equalTo('assignedExpert', null)
				.include('region')
				.include('snappUser')
				.ascending('createdAt')
				.limit(limit)

			if (type === 'localPlant') {
				snappInfoQuery
					.containedIn('region', expertRegions)
					.equalTo('snappType', 'Plant')
					.descending('createdAt')
			} else if (type === 'localPest') {
				snappInfoQuery
					.containedIn('region', expertRegions)
					.equalTo('snappType', 'PestAndDisease')
				// snappInfoQuery.greaterThanOrEqualTo("sendForIdentification", pestExpiryDate);
			} else if (type === 'myFolder') {
				snappInfoQuery.equalTo('assignedExpert', $scope.currentUser)
			} else if (type === 'mysteryPlant') {
				snappInfoQuery
					.lessThan('sendForIdentification', snappExpiryDate)
					.equalTo('snappType', 'Plant')
			} else if (type === 'mysteryPest') {
				snappInfoQuery
					.lessThan('sendForIdentification', snappExpiryDate)
					.equalTo('snappType', 'PestAndDisease')
			}

			if (lastSnappDate !== null) {
				snappInfoQuery.greaterThan('createdAt', lastSnappDate)
			}

			return snappInfoQuery
		}

		function expertAssignedToAnySnappQuery() {
			const snapp = Parse.Object.extend('Snapp')
			const query = new Parse.Query(snapp)
			query
				.equalTo('beingIdentifiedByExpert', $scope.currentUser)
				.notEqualTo('beingIdentifiedUntilTime', null)
			return query
		}

		function unsetBeingSnappIdentified() {
			if (currentSnappObject) {
				return currentSnappObject.unset('beingIdentifiedByExpert').save()
			} else return Promise.resolve()
		}

		function setBeingSnappIdentified() {
			if (currentSnappObject) {
				const isCurrentlyBeingIdentified = currentSnappObject
					.toJSON()
					.hasOwnProperty('beingIdentifiedByExpert')
				if (!isCurrentlyBeingIdentified) {
					expertAssignedToAnySnappQuery()
						.find()
						.then(snappObject => {
							if (snappObject.length) {
								const snapp = snappObject[0]
								snapp.unset('beingIdentifiedByExpert')
								snapp.unset('beingIdentifiedUntilTime')
								snapp.save().then(() => {
									currentSnappObject.set(
										'beingIdentifiedByExpert',
										$scope.currentUser
									)
									updateBeingIdentifiedUntilTime()
								})
							} else {
								currentSnappObject.set(
									'beingIdentifiedByExpert',
									$scope.currentUser
								)
								updateBeingIdentifiedUntilTime()
							}
						})
				} else {
					const beingIdentifiedByExpert = currentSnappObject.get(
						'beingIdentifiedByExpert'
					)
					const beingIdentifiedUntilTime = currentSnappObject.get(
						'beingIdentifiedUntilTime'
					)
					const now = new Date()
					if (
						!beingIdentifiedByExpert ||
						beingIdentifiedByExpert.id === $scope.currentUser.id ||
						beingIdentifiedUntilTime < now
					) {
						$scope.canBeIdentified = true
					} else {
						$scope.canBeIdentified = false
					}
				}
			}
		}

		function updateBeingIdentifiedUntilTime() {
			if (currentSnappObject && $scope.canBeIdentified) {
				const beingIdentifiedByExpert = currentSnappObject.get(
					'beingIdentifiedByExpert'
				)
				const beingIdentifiedUntilTime = currentSnappObject.get(
					'beingIdentifiedUntilTime'
				)
				const now = new Date()
				if (
					!beingIdentifiedByExpert ||
					beingIdentifiedByExpert.id === $scope.currentUser.id ||
					(beingIdentifiedUntilTime < now &&
						window.location.href.includes(currentSnappObject.id))
				) {
					currentSnappObject
						.set('beingIdentifiedByExpert', $scope.currentUser)
						.set(
							'beingIdentifiedUntilTime',
							new Date(new Date().getTime() + 5 * 60000) // lock for 5 mins
						)
						.save()
				} else if (
					beingIdentifiedByExpert &&
					beingIdentifiedByExpert.id !== $scope.currentUser.id
				) {
					$interval.cancel(setExpirationDateOfFlag)
					$scope.canBeIdentified = false
				}
			}
		}

		if ($scope.category === 'Plant') {
			$scope.safeApply(function() {
				$scope.displayCategory = 'Plant'
			})
		} else if ($scope.category === 'PestAndDisease') {
			$scope.safeApply(function() {
				$scope.displayCategory = 'Pest/Disease'
			})
		}

		var expertName =
			$scope.currentUser.get('firstName') +
			' ' +
			$scope.currentUser.get('lastName')

		$scope.identifySpinner = true
		$scope.identifypage = false
		$scope.showNewPlantDescription = false
		$scope.showGenusDropDown = false
		$scope.snappComments = []
		var currentSnappObject
		var addedPlantObjectId
		var userEmailId

		$scope.assignPlantFlag = $routeParams.type == 'Identify'
		$scope.sendEnabled = false
		if ($routeParams.type === 'Identify') {
			$scope.chatInputPlaceholderText =
				'Please assign a plant before sending a message'
			$scope.sendButtonText = 'Assign and Send'
		} else {
			$scope.chatInputPlaceholderText = ''
			$scope.sendEnabled = true
		}
		var snapp = Parse.Object.extend('Snapp')
		var snappComments = Parse.Object.extend('SnappComments')

		$(document).ready(function() {
			var plantobjectArray = []
			$('#updateplant').select2({
				placeholder: 'Select a Plant',
				minimumInputLength: 1,
				query: function(query) {
					plantIndex
						.search({ query: query.term, hitsPerPage: 10, page: 0 })
						.then(result => {
							let data = { results: [] }
							data.results = result.hits.map(hit => {
								let text = hit.latinName ? hit.latinName : ''
								text += hit.latinName && hit.commonName ? ' ' : ''
								text += hit.commonName ? hit.commonName : ''
								return {
									id: hit.objectID,
									text,
								}
							})
							query.callback(data)
						})
				},
			})
		})

		$(document).ready(function() {
			var genusObjectArray = []
			$('#plantGenus').select2({
				placeholder: 'Select a genus',
				minimumInputLength: 1,
				query: _.debounce(function(query) {
					var data = { results: [] }
					var genusTable = Parse.Object.extend('Genus')
					var getGenusQuery = new Parse.Query(genusTable)
					getGenusQuery.contains('genusNameLowerCase', query.term.toLowerCase())
					getGenusQuery.limit(1000)
					getGenusQuery.find().then(function(genusObject) {
						genusObjectArray = genusObject
						for (var i = 0; i < genusObject.length; i++) {
							data.results.push({
								id: genusObject[i].id,
								text: genusObject[i].get('genusName'),
							})
						}
						query.callback(data)
					})
				}, 500),
			})
		})

		$timeout(updateBeingIdentifiedUntilTime, 5000)

		$scope.goBack = function() {
			unsetBeingSnappIdentified().then(() => {
				window.history.back()
			})
		}

		$scope.redirectURL = function() {
			$timeout(callAtTimeout, 1000)
			function callAtTimeout() {
				$location.path('home')
			}
		}

		$scope.getRegionDetails = function() {
			var regionTable = Parse.Object.extend('Region')
			var getRegionQuery = new Parse.Query(regionTable)
			getRegionQuery.ascending('regionName')
			getRegionQuery.find().then(function(regionObject) {
				if (regionObject.length > 0) {
					$scope.jsonRegionArrayList = []
					for (var i = 0; i < regionObject.length; i++) {
						$scope.jsonRegionArrayList.push({
							regionName: regionObject[i].get('regionName'),
							object: regionObject[i],
							objectId: regionObject[i].id,
						})
					}
					$scope.spinnerProfile = true
				}
			})
		}

		$scope.getRegionDetails()

		$scope.getUserSnappRegion = function(regionObjectId) {
			var regionTable = Parse.Object.extend('Region')
			var getRegionQuery = new Parse.Query(regionTable)
			getRegionQuery.equalTo('objectId', regionObjectId)
			getRegionQuery.limit(1)
			getRegionQuery.find().then(function(regionObject) {
				if (regionObject.length > 0) {
					$scope.safeApply(function() {
						$scope.userRegion = regionObject[0].get('regionName')
					})
				}
			})
		}

		// Get select plant description
		$('#updateplant').on('select2-selecting', function(e) {
			$scope.showPlantDescription = false
			$scope.sendEnabled = false
			$scope.chatInputPlaceholderText = ''
			$scope.showNewPlantDescription = false
			$scope.showGenusDropDown = false
			$scope.showGenusName = false
			$scope.plantDescription = ''
			$scope.loadingPlantDescription = true
			document.getElementById('enterNewPlant').value = ''
			$scope.genus = ''
			$scope.displayCommentBox = true
			var plant = Parse.Object.extend('Plant')
			var query = new Parse.Query(plant)
			query
				.include('genus')
				.equalTo('objectId', e.val)
				.find()
				.then(plantDescription => {
					$scope.loadingPlantDescription = false
					var genusObject = plantDescription[0].get('genus')
					if (genusObject) {
						$scope.showGenusName = true
						$scope.genusName = genusObject.get('genusName')
					} else {
						$scope.genusName = '-'
						$scope.showGenusName = true
					}

					if (
						plantDescription[0].get('plantDescription') !== undefined &&
						plantDescription[0].get('plantDescription') !== null &&
						plantDescription[0].get('plantDescription') !== ''
					) {
						$scope.showPlantDescription = true
						$scope.sendEnabled = true
						$scope.plantDescriptionButton = 'Edit Plant Description'
						$scope.plantDescription = plantDescription[0].get(
							'plantDescription'
						)
					} else {
						$scope.plantDescription = '-'
						$scope.showPlantDescription = true
						$scope.sendEnabled = true
						$scope.plantDescriptionButton = 'Add Plant Description'
					}
				})
		})

		$scope.getExpertDetails = function(status) {
			var expertTable = Parse.Object.extend('ExpertDetails')
			var getExpertQuery = new Parse.Query(expertTable)
			getExpertQuery.include('user')
			getExpertQuery.find().then(function(expertObject) {
				if (expertObject.length > 0) {
					$scope.jsonExpertArrayList = []
					for (var i = 0; i < expertObject.length; i++) {
						var userObject = expertObject[i].get('user')
						if (userObject) {
							var firstName = userObject.get('firstName')
							var lastName = userObject.get('lastName')
							var email = userObject.get('email')
							var userObjectId = userObject.id
						}

						$scope.jsonExpertArrayList.push({
							firstName: firstName,
							objectId: userObjectId,
						})
					}
				}
			})
		}

		$scope.getExpertDetails()
		function mapOption(mapOptions, latitude, longitude) {
			$scope.map = new google.maps.Map(
				document.getElementById('googleMap'),
				mapOptions
			)
			new google.maps.Marker({
				map: $scope.map,
				position: new google.maps.LatLng(latitude, longitude),
			})
		}

		var snappInfoQuery = new Parse.Query(snapp)
		snappInfoQuery
			.equalTo('objectId', $routeParams.id)
			.include('expertIdentifiedPlant')
			.include('snappUser')
			.include('region')
			.include('extraPhotos')
			.limit(1)
		snappInfoQuery.find().then(function(snappObject) {
			if (snappObject.length > 0) {
				$scope.snapp = snappObject[0]
				$scope.jsonImageList = []

				var latitude = snappObject[0].get('latitude')
				var longitude = snappObject[0].get('longitude')
				if (latitude && longitude) {
					$scope.mapShow = true
					var mapOptions = {
						zoom: 10,
						center: new google.maps.LatLng(latitude, longitude),
						mapTypeId: google.maps.MapTypeId.TERRAIN,
					}
					mapOption(mapOptions, latitude, longitude)
				} else {
					$scope.mapShow = false
				}

				$scope.largeImageURL = snappObject[0].get('snappImage').url()
				$scope.snappImageURL = snappObject[0].get('snappImage')

				$scope.jsonImageList.push({
					thumbImage: snappObject[0].get('thumbImage'),
					image: snappObject[0].get('snappImage'),
				})

				var extraPhotos = snappObject[0].get('extraPhotos')
				if (extraPhotos) {
					for (var i = 0; i < extraPhotos.length; i++) {
						$scope.jsonImageList.push({
							thumbImage: extraPhotos[i].get('thumbImage'),
							image: extraPhotos[i].get('image'),
						})
					}
				}

				currentSnappObject = snappObject[0]
				$scope.sendForIdentification = moment(
					snappObject[0].get('sendForIdentification')
				).fromNow()

				var snappRegionObject = snappObject[0].get('region')
				if (snappRegionObject) {
					$scope.regionName = snappRegionObject.get('regionName')
				}

				var plantObject = snappObject[0].get('expertIdentifiedPlant')

				$scope.snappAddedDate = $scope.date

				snappUserObject = snappObject[0].get('snappUser')
				if (snappUserObject !== undefined) {
					userEmailId = snappUserObject.get('email')
					var regionObject = snappUserObject.get('region').id
					$scope.getUserSnappRegion(regionObject)
				}

				var assignedExpertObject = snappObject[0].get('assignedExpert')
				if (assignedExpertObject) {
					if (assignedExpertObject.id === $scope.currentUser.id) {
						$scope.displayAssignedComments = snappObject[0].get(
							'assignedComments'
						)
					}
				}

				$scope.isExpertCommentAdded =
					snappObject[0].get('isExpertCommentAdded') || false
				$scope.userCommentStatus =
					snappObject[0].get('userCommentStatus') || false

				setBeingSnappIdentified()

				// loadPlant Drop down;
				getSnappComments()

				if (plantObject !== undefined) {
					$scope.safeApply(function() {
						$scope.identifiedPlantName = plantObject.get('commonName')
						$scope.assignPlantFlag = false
						$scope.sendEnabled = true
						$scope.sendButtonText = 'Send'
					})
				}
			}
			$scope.identifySpinner = false
		})

		$scope.closeChat = function() {
			$scope.snapp.set('userCommentStatus', false)
			$scope.snapp.save().then(function() {
				$scope.userCommentStatus = false
			})
		}

		$scope.modalOpenIssueCredit = function() {
			$('#issueCredit').modal('show')
		}

		$scope.issueCredit = function() {
			Parse.Cloud.run('issueOneCreditForUser', {
				userId: snappUserObject.id,
			})
				.then(result => {
					console.log('Credit issued')
				})
				.catch(error => {
					console.log('Something went wrong ' + error.message)
				})

			$('#issueCredit').modal('hide')
		}

		$scope.modelOpen = function() {
			return new Promise((resolve, reject) => {
				var snappInfoQuery = new Parse.Query(snapp)
				snappInfoQuery.equalTo('objectId', $routeParams.id)
				snappInfoQuery.limit(1)
				snappInfoQuery.find().then(function(snappObjectCheck) {
					var plantObjectCheck = snappObjectCheck[0].get(
						'expertIdentifiedPlant'
					)
					if (plantObjectCheck) {
						alert('This snapp is identified by another expert.')
						reject()
					} else {
						$scope.spinnerNewPlantImage = true
						var updatePlant = $('#updateplant').select2('val')
						var enterNewPlant = document.getElementById('enterNewPlant').value
						var newPlantDescription = document.getElementById(
							'newPlantDescription'
						).value

						if (updatePlant === '' && enterNewPlant === '') {
							alert('Please select a plant or enter a plant name.')
							reject()
						} else {
							return checkAssignPlant(
								enterNewPlant,
								updatePlant,
								newPlantDescription,
								$('#plantGenus').select2('val')
							)
						}
					}
				})
			})
		}

		// snapp re-assign model
		$scope.modelOpenChangeStatus = function() {
			// Get current snapp region and assigned expert.
			var snappRegionObject = currentSnappObject.get('region')
			var snappAssignedExpertObject = currentSnappObject.get('assignedExpert')
			if (snappRegionObject != null && snappRegionObject !== undefined) {
				$scope.assignedRegion = snappRegionObject.id
			} else {
				$scope.assignedRegion = null
			}

			if (
				snappAssignedExpertObject !== null &&
				snappAssignedExpertObject !== undefined
			) {
				$scope.assignedExpert = snappAssignedExpertObject.id
			}
			$('#changeStatus').modal('show')
		}

		// Assign the snapp to another expert function.
		$scope.assignNewExpert = function(expertObjectId) {
			var UserProfile = Parse.Object.extend('_User')
			var query = new Parse.Query(UserProfile)
			query
				.equalTo('objectId', expertObjectId)
				.find()
				.then(function(UserProfileData) {
					if (UserProfileData.length > 0) {
						var assignedUserEmailId = UserProfileData[0].get('email')
						currentSnappObject.set('assignedExpert', UserProfileData[0])
						currentSnappObject.save()

						if (!$scope.currentUser.get('firstName')) {
							var expertName = 'Expert'
						} else {
							var expertName = $scope.currentUser.get('firstName')
						}

						Parse.Cloud.run('sendEmailSnappAssignedExpert', {
							email: assignedUserEmailId,
							assignedBy: expertName,
						})
							.then(result => {
								console.log('Email Send to expert!')
							})
							.catch(error => {
								console.log('Email Sending expert fails' + error.message)
							})
					}
				})
		}

		// Re-assign Block, Post comments for the particular snapp.
		$scope.assignSnappStatus = function() {
			$scope.safeApply(function() {
				$scope.assignChangeStatus = true
			})

			if (currentSnappObject.get('region').id !== $scope.assignedRegion) {
				var regionClass = Parse.Object.extend('Region')
				var newRegionObject = new regionClass()
				newRegionObject.id = $scope.assignedRegion
				currentSnappObject.set('region', newRegionObject)
				currentSnappObject.save()
				$scope.redirect = true
			}

			if (
				currentSnappObject.get('assignedExpert') !== null &&
				currentSnappObject.get('assignedExpert') !== undefined
			) {
				if (
					currentSnappObject.get('assignedExpert').id !== $scope.assignedExpert
				) {
					$scope.assignNewExpert($scope.assignedExpert)
					$scope.redirect = true
				}
			} else {
				if ($scope.assignedExpert) {
					$scope.assignNewExpert($scope.assignedExpert)
					$scope.redirect = true
				}
			}

			if ($scope.assignComments) {
				currentSnappObject.set(
					'assignedComments',
					'<b>' +
						$scope.currentUser.get('firstName') +
						'</b> : ' +
						$scope.assignComments
				)
				currentSnappObject.save()
				$scope.redirect = true
			}

			$('#changeStatus').modal('hide')

			$scope.safeApply(function() {
				$scope.assignChangeStatus = false
			})

			if ($scope.redirect === true) {
				alert('Snapp reassigned successfully')
				$scope.redirectURL()
			}
		}

		// Comment description block
		$scope.addPlantDescription = function() {
			var plantDescription = document.getElementById('plantDescription').value
			if (plantDescription === '') {
				alert('Please enter comments')
				return false
			} else {
				$scope.plantDescriptionSpinnerFlag = true
				var plant = Parse.Object.extend('Plant')
				var query = new Parse.Query(plant)
				query
					.equalTo('objectId', $('#updateplant').select2('val'))
					.limit(1)
					.find()
					.then(results => {
						if (results.length >= 1) {
							results[0].set('plantDescription', plantDescription)
							results[0]
								.save(null)
								.then(newPlant => {
									$scope.safeApply(function() {
										$scope.plantDescriptionSpinnerFlag = false
										$('#plantDescriptionStatus').modal('hide')
									})
								})
								.catch(error => {})
						}
					})
					.catch(error => {
						alert('Error: ' + error.code + ' ' + error.message)
					})
			}
		}

		$scope.clearSelectedPlant = function() {
			$scope.displayCommentBox = true
			$scope.showGenusName = false
			$scope.showPlantDescription = false
			$scope.sendEnabled = false
			$scope.showNewPlantDescription = true
			$scope.showGenusDropDown = true
			$('#updateplant').select2('val', '')
		}

		$scope.addMessage = function() {
			if ($scope.assignPlantFlag) {
				// we must assign the plant before sending the message
				$scope
					.assignPlant(null)
					.then(function() {
						sendMessage()
						$scope.assignPlantFlag = false
						$scope.showPlantDescription = false
					})
					.catch(function(err) {})
			} else {
				sendMessage()
			}
		}

		function sendMessage() {
			return new Promise((resolve, reject) => {
				const SnappComment = Parse.Object.extend('SnappComments')
				let snappComment = new SnappComment()

				$scope.sendEnabled = false
				snappComment.set({
					comments: $scope.messageText,
					expert: $scope.currentUser.toPointer(),
					snapp: $scope.snapp.toPointer(),
				})
				snappComment
					.save()
					.then(snappComment => {
						$scope.sendEnabled = true
						$scope.messageText = ''
						$scope.chatInputPlaceholderText = ''
						addSnappComment(snappComment)
						updateScroll()
						$scope.$apply()
						resolve()
					})
					.catch(err => {
						$scope.sendEnabled = true
						$scope.alertMessageText = 'Could not send message'
						$scope.showAlertMessage = true
						$timeout(function() {
							$scope.showAlertMessage = false
						}, 3000)
						reject()
					})
			})
		}

		function addSnappComment(snappComment) {
			let snappCommentUser =
				snappComment.get('user') || snappComment.get('expert')

			$scope.snappComments.push({
				username: Helpers.getUsername(snappCommentUser),
				comments: snappComment.get('comments'),
				objectId: snappComment.id,
				object: snappComment,
				me: snappCommentUser.id === $scope.currentUser.id,
				createdAt: moment(snappComment.get('createdAt')).fromNow(),
			})
		}

		function updateScroll() {
			var chatMessages = document.getElementById('chat')
			chatMessages.scrollTop = chatMessages.scrollHeight
		}

		function checkAssignPlant(
			enterNewPlant,
			updatePlant,
			newPlantDescription,
			genusObjectId
		) {
			// if the user enter the new plant name this block will run.
			if (enterNewPlant !== '') {
				$scope.identifySpinner = true

				$scope.hideMap = true

				var plant = Parse.Object.extend('Plant')
				var query = new Parse.Query(plant)
				return query
					.equalTo('commonNameLowerCase', enterNewPlant.toLowerCase())
					.find()
					.then(results => {
						if (results.length == 0) {
							var plantTable = Parse.Object.extend('Plant')
							var newPlant = new plantTable()

							if (genusObjectId) {
								var genusTable = Parse.Object.extend('Genus')
								var genusDetails = new genusTable()
								genusDetails.id = genusObjectId
								newPlant.set('genus', genusDetails)
							}

							return newPlant
								.set('commonName', enterNewPlant)
								.set('plantDescription', newPlantDescription)
								.set('commonNameLowerCase', enterNewPlant.toLowerCase())
								.set('identifyAdd', 'Expert')
								.set('addedUser', $scope.currentUser)
								.save(null)
								.then(newPlant => {
									console.log('plant added successfully')
									return Parse.Cloud.run(
										'ExpertAddedPlantMailToAdmin',
										{}
									).then(result => {
										console.log('Email Success')
										addedPlantObjectId = newPlant.id
										return $scope.assignPlant(addedPlantObjectId)
									})
								})
						} else {
							$scope.safeApply(function() {
								$scope.hideMap = false
								$scope.identifySpinner = false
							})
							alert('Plant name already exists')
							return Promise.reject()
						}
					})
			} else {
				// Loading image in the modal popup select plant image vs snapp image.
				$('#assignModal').modal('show')

				var plantPhoto = Parse.Object.extend('PlantPhoto')
				var plantPhotoQuery = new Parse.Query(plantPhoto)
				plantPhotoQuery.equalTo('plantId', updatePlant)
				plantPhotoQuery.limit(1)
				return plantPhotoQuery.find().then(function(plantPhotoObject) {
					$scope.safeApply(function() {
						if (plantPhotoObject.length > 0) {
							$scope.plantImage = plantPhotoObject[0].get('plantImage').url()
							$scope.spinnerNewPlantImage = false
						} else {
							$scope.plantImage = $scope.largeImageURL
							$scope.spinnerNewPlantImage = false
						}
					})
				})
			}
		}

		// By default comments listed query as 100.
		function getSnappComments() {
			$scope.spinnercomments = true
			$scope.snappComments = []
			var snappCommentQuery = new Parse.Query(
				Parse.Object.extend('SnappComments')
			)
			snappCommentQuery.equalTo('snapp', currentSnappObject)
			snappCommentQuery.ascending('createdAt')
			snappCommentQuery.find().then(function(commentsObject) {
				if (commentsObject.length > 0) {
					$scope.commentText = ''
					for (let snappComment of commentsObject) {
						if (snappComment.get('user')) {
							$scope.userComments = snappComment.get('comments')
						}

						let snappCommentUser =
							snappComment.get('user') || snappComment.get('expert')

						$scope.snappComments.push({
							username: Helpers.getUsername(snappCommentUser),
							comments: snappComment.get('comments'),
							objectId: snappComment.id,
							object: snappComment,
							me: snappComment.id === $scope.currentUser.id,
							createdAt: moment(snappComment.get('createdAt')).fromNow(),
						})
					}
				}
				$scope.spinnercomments = false
				$scope.showmap = true
			})
		}

		function commentFunction(status) {
			if ($scope.commentText) {
				$scope.spinnercomments = true
				var insertSnappComments = new snappComments()
				insertSnappComments.set('comments', $scope.commentText)
				insertSnappComments.set('expert', $scope.currentUser)
				insertSnappComments.set('snapp', currentSnappObject)
				insertSnappComments
					.save(null)
					.then(insertSnappCommentsObject => {
						$scope.safeApply(function() {
							currentSnappObject.set('userCommentStatus', false)
							currentSnappObject
								.save(null)
								.then(snappObjectUpdate => {
									$scope.spinnercomments = false
									if (currentSnappObject.id && status === true) {
										console.log($scope.commentText)
										Parse.Cloud.run(
											'SendIdentificationEmailTemplatePushNotification',
											{
												snappId: currentSnappObject.id,
												expertComment: $scope.commentText,
												type: false,
											}
										)
											.then(result => {
												console.log(result)
											})
											.catch(error => {
												console.log('Error Sending Email' + error.message)
											})
									}
									getSnappComments()
								})
								.catch(error => {
									$scope.safeApply(function() {
										alert('This is the error message :' + error.message)
										$scope.spinnercomments = false
									})
								})
						})
					})
					.catch(error => {
						$scope.safeApply(function() {
							alert('This is the error message :' + error.message)
							$scope.spinnercomments = false
						})
					})
			}
		}

		// Post comments for the particular snapp.

		$scope.submitComment = function() {
			var commentText = document.getElementById('commentText').value
			if (commentText === '') {
				alert('Please enter comments')
				return false
			} else {
				commentFunction(true)
			}
		}

		$scope.modelPlantDescriptionStatus = function() {
			var data = $('#updateplant').select2('data')
			if (data) {
				$scope.selectedPlantName = data.text
			}
			$('#plantDescriptionStatus').modal('show')
		}

		$scope.loadLargeImage = function(largeImageURL) {
			$scope.largeImageURL = largeImageURL
		}

		$scope.getProductDetails = function(snappId, addedPlantObjectId) {
			var genusProductArray = []
			var categoryProductArray = []
			var productArray = []
			var temProductArray = []
			var arr = []

			var snappTable = Parse.Object.extend('Snapp')
			var snappQuery = new Parse.Query(snappTable)
			snappQuery.equalTo('objectId', snappId)
			snappQuery.include(
				'expertIdentifiedPlant.genus.genusProduct.categoryType.recommendedProducts'
			)
			snappQuery.include('snappUser.region')
			snappQuery.include('expertIdentifiedPlant.genus.product')
			snappQuery.include(
				'expertIdentifiedPlant.genus.categoryType.recommendedProducts'
			)
			snappQuery.descending('updatedAt')
			snappQuery
				.first()
				.then(results => {
					var userObject = results.get('snappUser')
					var regionObject = userObject.get('region')
					var regionName = regionObject.get('regionName')
					var plantObject = results.get('expertIdentifiedPlant')
					var genusObject = plantObject.get('genus')
					if (genusObject) {
						var genusProduct = genusObject.get('genusProduct')
						if (genusProduct) {
							for (var i = 0; i < genusProduct.length; i++) {
								var genusProductRegionArray = []
								if (
									genusProduct[i] &&
									genusProduct[i].get('Regions') != undefined
								) {
									for (
										var j = 0;
										j < genusProduct[i].get('Regions').length;
										j++
									) {
										genusProductRegionArray.push(
											genusProduct[i].get('Regions')[j].id
										)
									}
									if (regionObject && genusProduct[i].get('Regions')) {
										if (
											$.inArray(regionObject.id, genusProductRegionArray) != -1
										) {
											productArray.push({
												productImage: genusProduct[i].get('productImage').url(),
												productName: genusProduct[i].get('productName'),
												productLink: genusProduct[i].get('productLink'),
												updateAt: genusProduct[i].updatedAt,
											})
										}
									}
								}
							}
							productArray = _.sortBy(productArray, function(o) {
								var dt = new Date(o.updateAt)
								return -dt
							})
						} else {
							genusProduct = []
						}

						if (productArray.length < 3) {
							var categoryObject = genusObject.get('categoryType')
							if (categoryObject) {
								var categoryProduct = categoryObject.get('recommendedProducts')
								if (categoryProduct) {
									var limit = 3 - productArray.length

									for (var i = 0; i < categoryProduct.length; i++) {
										var categoryProductRegionArray = []
										if (
											categoryProduct[i] &&
											categoryProduct[i].get('Regions') != undefined
										) {
											for (
												var j = 0;
												j < categoryProduct[i].get('Regions').length;
												j++
											) {
												categoryProductRegionArray.push(
													categoryProduct[i].get('Regions')[j].id
												)
											}
											if (regionObject && categoryProduct[i].get('Regions')) {
												if (
													$.inArray(
														regionObject.id,
														categoryProductRegionArray
													) != -1
												) {
													categoryProductArray.push({
														productImage: categoryProduct[i]
															.get('productImage')
															.url(),
														productName: categoryProduct[i].get('productName'),
														productLink: categoryProduct[i].get('productLink'),
														updateAt: categoryProduct[i].updatedAt,
													})
												}
											}
										}
									}

									categoryProductArray = _.sortBy(
										categoryProductArray,
										function(o) {
											var dt = new Date(o.updateAt)
											return -dt
										}
									)

									if (categoryProductArray.length <= limit) {
										limit = categoryProductArray.length
									}

									for (var j = 0; j < limit; j++) {
										temProductArray.push({
											productImage: categoryProductArray[j].productImage,
											productName: categoryProductArray[j].productName,
											productLink: categoryProductArray[j].productLink,
											updateAt: categoryProductArray[j].updatedAt,
										})
									}

									var arr = [].concat(productArray, temProductArray)
								} else {
									var arr = productArray
								}
							} else {
								var arr = productArray
							}
						} else {
							var arr = productArray
						}
					}

					Parse.Cloud.run('SendIdentificationEmailTemplatePushNotification', {
						snappId: $scope.snappId,
						expertComment: $scope.commentText,
						type: 'snappIdentify',
						productArr: arr,
					})
						.then(result => {
							console.log(result)
							console.log('Snapp details update success')
							$scope.safeApply(function() {
								$scope.assignPlantSpinner = false
								$scope.identifySpinner = false
								$scope.assignPlantFlag = false
								$scope.sendEnabled = true
								if (addedPlantObjectId !== null) {
									$scope.identifiedPlantName = document.getElementById(
										'enterNewPlant'
									).value
									$scope.hideMap = false
								} else {
									var data = $('#updateplant').select2('data')
									$scope.identifiedPlantName = data.text
								}
								$('#assignModal').modal('hide')
							})
						})
						.catch(error => {
							console.log('Error Sending Email' + error.message)
						})
				})
				.catch(error => {
					console.log(' aa' + error.message)
				})
		}

		$scope.assignPlant = function(addedPlantObjectId) {
			$('#assignButton').attr('disabled', 'disabled')
			$scope.assignPlantSpinner = true
			var selectedId

			if (addedPlantObjectId === null) {
				selectedId = $('#updateplant').select2('val')
			} else {
				selectedId = addedPlantObjectId
			}

			if (addedPlantObjectId === null) {
				$scope.identifiedPlantName = document.getElementById(
					'enterNewPlant'
				).value
				$scope.hideMap = false
			} else {
				var data = $('#updateplant').select2('data')
				$scope.identifiedPlantName = data ? data.text : ''
			}

			return Parse.Cloud.run('assignPlantToSnapp', {
				plantObjectId: selectedId,
				userObjectId: $scope.currentUser.id,
				snappObjectId: currentSnappObject.id,
				snappImage: $scope.snappImageURL,
			})
				.then(result => {
					commentFunction(false)
					$scope.sendEnabled = true
					$scope.sendButtonText = 'Send'
					$scope.getProductDetails($scope.snappId, addedPlantObjectId)
				})
				.catch(error => {
					console.log('Snapp details update failed' + error.message)
					$scope.assignPlantSpinner = false
					$scope.identifySpinner = false
					$scope.assignPlantFlag = false
					$scope.sendEnabled = false
				})
		}
	},
])
